import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxEventsUrl = `${constants.ajax.baseUrl}/events`;
const ajaxOrderEventsUrl = `${constants.ajax.baseUrl}/events/order`;

export const ajaxGetEvents = (params) =>
  new Promise((res, rej) => {
    var requestParams = [];
    if (params && params.limit) {
        requestParams.push(`limit=${params.limit}`);
    }
    if (params && params.offset) {
        requestParams.push(`offset=${params.offset}`);
    }
    if (params && params.search) {
      requestParams.push(`search=${encodeURIComponent(params.search)}`);
    }
    ajaxCall(ajaxEventsUrl + (requestParams.length > 0 ? '?' + requestParams.join('&') : '') , formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  });

export const ajaxGetOrderEvents = (params) =>
  new Promise((res, rej) => {
    if ( !params || params.orderId === -1 ) {
      rej();
    }
    var requestParams = [];
    if (params && params.limit) {
        requestParams.push(`limit=${params.limit}`);
    }
    if (params && params.offset) {
        requestParams.push(`offset=${params.offset}`);
    }
    if (params && params.search) {
      requestParams.push(`search=${encodeURIComponent(params.search)}`);
    }
    ajaxCall(ajaxOrderEventsUrl + `/${params.orderId}` + (requestParams.length > 0 ? '?' + requestParams.join('&') : '') , formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  });

export const ajaxAddEvent = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxEventsUrl + '/add', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });
