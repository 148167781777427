import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ajaxGetUsers,
  ajaxSaveUser,
  ajaxDisableUser,
  ajaxEnableUser,
  ajaxDeleteUser
} from '../services/adminService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import ConfirmationButton from '../components/ConfirmationButton';
import { isPhone } from '../helpers';

export default function User(props) {
  const incomingParams = useParams();

  const [user, setUser] = useState({user_role: 'sale'});

  const allFields = useMemo(
    () => [
      {
        key: 'username',
        type: 'text',
        label: 'Username',
        autoComplete: 'username',
        disabled: user && user['username'] === 'admin',
        minLength: 4,
        autoFocus: true
      },
      {
        key: 'password',
        type: 'password',
        label: 'Password',
        autoComplete: 'new-password',
        minLength: 6
      },
      {
        key: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        autoComplete: 'confirm-password',
        confirm: 'password'
      },
      {
        key: 'user_role',
        label: 'User Role',
        type: 'list',
        disabled: user && user['username'] === 'admin',
        dataset: user && user['username'] === 'admin'
          ? [{ value: 'administrator', label: 'Administrator' }]
          : [
              { value: 'administrator', label: 'Administrator' },
              { value: 'sale', label: 'Sale' },
              { value: 'shipping', label: 'Shipping' },
              { value: 'production', label: 'Production' }
            ]
      },
      {
        key: 'first_name',
        type: 'text',
        label: 'First Name',
      },
      {
        key: 'last_name',
        type: 'text',
        label: 'Last Name',
      },
      {
        key: 'phone',
        type: 'text',
        label: 'Phone Number'
      },
      {
        key: 'email',
        type: 'text',
        label: 'Email',
        autoComplete: 'email'
      }
    ],
    [user]
  );
  

  const requiredFields = useMemo(() => {
    let retRequiredFields = ['first_name', 'last_name', 'username', 'user_role'];
    if (props.add) {
      retRequiredFields.push('password');
    }
    return retRequiredFields;
  }, [props.add]);

  const emailFields = useMemo(() => ['email'], []);
  const phoneFields = useMemo(() => [], []);

  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const navigate = useNavigate();
  const paramUserId = (incomingParams && incomingParams.UserId ? incomingParams.UserId : -1);

  useEffect(() => {
    if (!props.add) {
      if (paramUserId !== -1) {
        const params = { UserId: paramUserId };

        ajaxGetUsers(params)
        .then((res) => {
          const { data } = res;
          setUser(data);
        })
        .catch(() => {
          setUser({});
        });
      }
    }
  }, [paramUserId, props.add]);

  const validateFields = useCallback(() => {
    const fields = { ...user };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && phoneFields.includes(key) && !isPhone(fieldValue)) {
        newErros[key] = 'Please enter a valid phone number';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [user, allFields, requiredFields, emailFields, phoneFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;

    const params = { ...user };
    let url = '/users';

    ajaxSaveUser(params)
      .then(() => navigate(url))
      .catch(() => navigate(url));
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;
    
    setUser({ ...user, [key]: value });
  };

  const getFieldValue = (key) => {
    return user && user[key] !== undefined && user[key] !== null ? user[key] : '';
  };

  const getFieldPlaceholder = (key) => {
    return key === 'password' && !props.add ? 'leave blank to not change the password' : '';
  };

  const getFieldErrorText = (key) => {
    if (errors && errors[key]) return errors[key];
    else return '';
  };

  const checkFieldError = (key) => {
    if (errors && errors[key]) return true;
    else return false;
  };

  const handleManagerToggle = (isEnabled, id) => {
    let ajaxMethod = ajaxDisableUser
    if (isEnabled) {
      ajaxMethod = ajaxEnableUser;
    }

    ajaxMethod({ UserId: id })
      .then((res) => setUser(res.data))
      .catch((e) => console.log(e.message));
  };

  const handleManagerDelete = (id) => {
    const url = '/users';
    ajaxDeleteUser({ UserId: id })
      .then(() => navigate(url))
      .catch((e) => console.log(e.message));
  };

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Add / Edit User">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            {allFields.map((entry) => (
              <React.Fragment key={entry['key']}>
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'list'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        error={checkFieldError(entry['key'])}
                        helperText={getFieldErrorText(entry['key'])}
                        sx={{ width: '100%' }}
                      >
                        {(entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              {user && user['username'] !== 'admin' && user['active'] > 0 && (
                <Button onClick={() => handleManagerToggle(false, user['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px', backgroundColor: 'red' }}>
                  Disable user
                </Button>
              )}
              {user && user['username'] !== 'admin' && user['active'] < 1 && (
                <Button onClick={() => handleManagerToggle(true, user['id'])} variant="contained" size="normal" style={buttonStyle} sx={{ mr: '20px' }}>
                  Enable user
                </Button>
              )}
              {user && user['username'] !== 'admin' && user['deleted'] === 0 && (
                <ConfirmationButton
                  buttonProps={{
                    variant: 'contained',
                    size: 'medium',
                    sx: { mr: '20px', backgroundColor: 'red' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={() => handleManagerDelete(user['id'])}
                  buttonText="Delete user"
                  dialogText="Are you sure you want to delete this user?"
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              )}
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Save User
              </Button>
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/users">
                Cancel
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
