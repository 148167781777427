import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell  from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinkIcon from '@mui/icons-material/Link';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import validator from 'validator';
import { isFloat, priceFormat, calcTotalPrice, noty } from '../helpers';
import { ajaxGetProducts, ajaxSaveProduct } from '../services/productService';

const filter = createFilterOptions();

function SelectProductDialog(props) {
  const { open, onClose, onSelect, products } = props
  const emptyProduct = {desc:'', product_code:'', msrp: 0, discount: 0, is_shipping_cost: 0, active: 1};

  const [product, setProduct] = useState(emptyProduct);
  const [edit, setEdit] = useState(false); 
  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleProductChange = (_, value) => {
    if (typeof value === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        setProduct({...emptyProduct, product_code: value})
        setEdit(true);
      });
    } else if (value && value.inputValue) {
      setProduct({...emptyProduct, product_code: value.inputValue})
      setEdit(true);
    } else{
      const p = value && products.find(p => p.id === value.id);
      setProduct(p || emptyProduct)
      setEdit(false);
    }
  }

  const getFieldValue = (key) => {
    if(product) {
      if(key === 'name' && product['id']){
        return '[' + product['product_code'] + '] ' + product['desc'];
      } else if(key === 'name' && !product['id']){
        return product['product_code'];
      } else {
        return product[key] || '';
      }
    }
    return '';
  };

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : '';

  const checkFieldError = (key) => errors && errors[key];

  const validateFields = useCallback(() => {
    let newErros = {};
    let result = true;

    if(validator.isEmpty(product['product_code'] || '', { ignore_whitespace: true })) {
      newErros['product_code'] = 'required field';
      result = false;
    }
    if(validator.isEmpty(product['desc'] || '', { ignore_whitespace: true })) {
      newErros['desc'] = 'required field';
      result = false;
    }
    if(product['msrp'] && !isFloat(product['msrp'])) {
      newErros['msrp'] = 'Please enter a valid number';
      result = false;
    }
    if (product['discount'] && (isNaN(product['discount']) || parseFloat(product['discount']) < 0 || parseFloat(product['discount']) > 100)) {
      newErros['discount'] = `Please enter a value between ${0} and ${100}.`;
      result = false;
    }
    
    setErrors(newErros);
    return result;
  }, [product]);

  useEffect(() => {
    submitOnce && validateFields();
  }, [validateFields, submitOnce]);

  const handleFieldChange = (event, val) => {
    const value = val !== undefined ? val : event.target.value, key = event.target.name;
    setProduct({ ...product, [key]: value });
  };

  const handleSelect = () => {
    if(edit) {
      setSubmitOnce(true);

      if(!validateFields()) return;

      const params = { ...product };
      setIsSaving(true);
      ajaxSaveProduct(params)
        .then((res) => {
          setIsSaving(false);
          onSelect({...product, id: res.data.product_id}, true);
          handleClose();
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      onSelect(product, false);
      handleClose();
    }
  };

  const handleClose = () => {
    setProduct(emptyProduct);
    setErrors({});
    setEdit(false);
    setSubmitOnce(false);
    onClose();
  };

  const isSelected = product && product['product_code'].trim() !== '';

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add product</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: '20px' }}>
            Select product from list or create new product.
          </DialogContentText>
          <Autocomplete
            size="small"
            value={getFieldValue('name')}
            options={(products || []).map( v => ({id: v.id, label: "[" + v.product_code + "] " + v.desc}))}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} size="small" label="Product" />}
            onChange={handleProductChange}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            freeSolo
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.label);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Create "${inputValue}"`,
                });
              }
              return filtered;
            }}
          />
          {edit &&
            <Grid container spacing={2} sx={{ mt: '20px' }}>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  size="small"
                  type="text"
                  name="desc"
                  value={getFieldValue('desc')}
                  onChange={handleFieldChange}
                  error={checkFieldError('desc')}
                  helperText={getFieldErrorText('desc')}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="MSRP"
                  size="small"
                  type="text"
                  name="msrp"
                  value={getFieldValue('msrp')}
                  onChange={handleFieldChange}
                  error={checkFieldError('msrp')}
                  helperText={getFieldErrorText('msrp')}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Discount %"
                  size="small"
                  type="text"
                  name="discount"
                  value={getFieldValue('discount')}
                  onChange={handleFieldChange}
                  error={checkFieldError('discount')}
                  helperText={getFieldErrorText('discount')}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Item is shipping cost"
                  control={<Checkbox name="is_shipping_cost" value={1} />}
                  onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                  checked={!!parseInt(getFieldValue('is_shipping_cost'), 10)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Warning"
                  size="small"
                  type="text"
                  name="warning"
                  value={getFieldValue('warning')}
                  onChange={handleFieldChange}
                  error={checkFieldError('warning')}
                  helperText={getFieldErrorText('warning')}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!isSelected || isSaving} onClick={handleSelect}>{edit ? 'Save and Select' : 'Select'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ProductRow({index, product, handleFieldChange, deleteProduct, moveOrderProduct, beforeRedirect}) {
  const dragRef = useRef(null)
  const previewRef = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: 'product',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!previewRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = previewRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      
      moveOrderProduct(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ opacity }, drag, preview] = useDrag({
    type: 'product',
    item: () => {
      return { id: product.id, index }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  })

  drag(dragRef)
  drop(preview(previewRef))

  const navigate = useNavigate();

  const onRedirect = ()=>{
    if(beforeRedirect){
      beforeRedirect();
    }
    navigate(`/products/edit/${product.product_id}`);
  }

  return (
    <TableRow style={{ opacity }} ref={previewRef} data-handler-id={handlerId}>
      <TableCell ref={dragRef} style={{ cursor: 'grab'}}>{index + 1}</TableCell>
      <TableCell style={{ maxWidth: 400, width: '45%'}}>
        <TextField
          name="name"
          size="small"
          value={product.name}
          onChange={handleFieldChange}
          placeholder="Product"
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton onClick={onRedirect} edge="end"><LinkIcon /></IconButton>
              </InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell style={{ maxWidth: 90}}>
        <TextField
          name="quantity"
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={product.quantity}
          onChange={handleFieldChange}
          placeholder="quantity"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </TableCell>
      <TableCell style={{ maxWidth: 120}}>
        <TextField
          name="msrp"
          size="small"
          value={product.msrp}
          onChange={handleFieldChange}
          placeholder="msrp"
          InputLabelProps={{ shrink: true }}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </TableCell>
      <TableCell style={{ maxWidth: 90}}>
        <TextField
          name="discount"
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={product.discount}
          onChange={handleFieldChange}
          placeholder="discount"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </TableCell>
      <TableCell>{priceFormat(calcTotalPrice(product))}</TableCell>
      <TableCell>
        <IconButton onClick={deleteProduct}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default function OrderProducts(props) {
  const {orderProducts, updateOrderProducts, dealerChanged, dealerDiscount, beforeRedirect} = props;

  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    ajaxGetProducts({})
      .then((res) => {
        const { data } = res;
        setProducts(data);
      })
      .catch(() => {
        setProducts([]);
      });
  }, []);

  useEffect(() => {
    if(dealerChanged) {
      setNeedUpdate(true);
    }
  }, [dealerChanged, dealerDiscount]);

  useEffect(() => {
    if(needUpdate){
      const newOrderProducts = orderProducts.map(p=>{
        const product = products.find(v => v.id === p.product_id);
        return {...p, discount: product ? product.discount || (!product.is_shipping_cost ? dealerDiscount : 0) : dealerDiscount};
      });
      newOrderProducts && updateOrderProducts(newOrderProducts);
      setNeedUpdate(false)
    }
  }, [needUpdate, dealerDiscount, orderProducts, products, updateOrderProducts]);

  const addProduct = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const deleteProduct = (index) => () => {
    updateOrderProducts(orderProducts.filter((_,i)=> i !== index))
  }

  const handleFieldChange = (index) => (event) => {
    const key = event.target.name;
    const  value = event.target.inputMode === 'numeric' 
      ? parseFloat(event.target.value.replace(/\D/g,'') || 0) 
      : event.target.value;

    updateOrderProducts(orderProducts.map((item, i) => i === index ? {...item, [key]: value} : item ));
  }

  const handleSelect = (product, isNew) => {
    setOpen(false);
    let newProducts = products
    if(isNew){
      newProducts = [...products, product]
      setProducts(newProducts)  
    }

    const newOrderProduct = {
      id: Math.floor(Math.random() * 1000000),
      product_id: product.id,
      name: '[' + product.product_code + '] ' + product.desc,
      quantity: 1,
      msrp: product.msrp,
      discount: product.discount || (!product.is_shipping_cost ? dealerDiscount : 0),
      is_shipping_cost: product.is_shipping_cost,
      active: 1
    }

    updateOrderProducts([...orderProducts, newOrderProduct]);

    if(!!product.warning) {
      noty(product.warning, 'warning');
    }
  }

  const moveOrderProduct = useCallback((dragIndex, hoverIndex) => {
    updateOrderProducts(
        update(orderProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, orderProducts[dragIndex]],
          ],
        }),
      )
  }, [orderProducts, updateOrderProducts]);

  return (
    <div>
      <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          startIcon={<AddIcon />}
          onClick={addProduct}
        >
          Add product
      </Button>
      <DndProvider backend={HTML5Backend}>
        { orderProducts.length === 0
          ? <div style={{ textAlign: 'center'}}>This quotation has no products</div>
          : <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '1px'}}>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Dis.(%)</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell style={{ width: '1px'}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderProducts.map((p,i)=>
                  <ProductRow
                    key={p.id}
                    index={i}
                    product={p}
                    handleFieldChange={handleFieldChange(i)}
                    deleteProduct={deleteProduct(i)}
                    moveOrderProduct={moveOrderProduct}
                    beforeRedirect={beforeRedirect}
                  />
                )}
              </TableBody>
            </Table>
        }
      </DndProvider>
      <SelectProductDialog open={open} products={products} onSelect={handleSelect} onClose={handleClose}/>
    </div>
  );
}