import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import inMemoryUser from '../../services/inMemoryUser';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StyledMainGrid from '../../components/StyledMainGrid';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import FileUploader from '../../components/FileUploader';
import ConfirmationButton from '../../components/ConfirmationButton';
import ProductStatisticDialog from '../../components/ProductStatisticDialog';
import {isFloat, downloadFile} from '../../helpers';
import {
  ajaxGetProducts, ajaxSaveProduct,
  ajaxDisableProduct, ajaxEnableProduct,
  ajaxGetProductImageUrl, ajaxDeleteProductImage
} from '../../services/productService';

export default function Product(props) {
  const incomingParams = useParams();

  const [product, setProduct] = useState({ msrp: 0, discount: 0, active: 1 });
  
  const [isSaving, setIsSaving] = useState(false);
  const [isProcessingImg, setIsProcessingImg] = useState(false);
  const [isEnableDisable, setIsEnableDisable] = useState(false);

  const [openStatistic, setOpenStatistic] = useState(false);
  const [productImgUrl, setProductImgUrl] = useState('');

  const isShippingView = inMemoryUser.isShipping();

  const allFields = useMemo(
    () => [
      {
        key: 'product_code',
        type: 'text',
        label: 'Product Code',
        autoFocus: true,
        disabled: isShippingView
      },
      {
        key: 'hs_code',
        type: 'text',
        label: 'HS Code'
      },
      {
        key: 'desc',
        type: 'text',
        label: 'Description',
        multiline: true,
        disabled: isShippingView
      },
      {
        key: 'msrp',
        type: 'text',
        label: 'MSRP',
        disabled: isShippingView
      },
      {
        key: 'discount',
        type: 'text',
        label: 'Discount %',
        min: 0,
        max: 100,
        disabled: isShippingView
      },
      {
        key: 'product_image',
        type: 'file',
        label: 'Product Image',
        accept: 'image/*',
        disabled: isShippingView
      },
      {
        key: 'is_shipping_cost',
        type: 'checkbox',
        label: 'Item is shipping cost',
        disabled: isShippingView
      },
      {
        key: 'warning',
        type: 'text',
        label: 'Warning',
        multiline: true,
        disabled: isShippingView
      }
    ],
    [isShippingView]
  );
  

  const requiredFields = useMemo(() => ['product_code', 'desc', 'msrp'], []);
  const floatFields = useMemo(() => ['discount', 'msrp'], []);

  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);

  const navigate = useNavigate();

  const paramProductId = (incomingParams && incomingParams.ProductId ? incomingParams.ProductId : -1);

  useEffect(() => {
    if (!props.add) {
      if (paramProductId !== -1) {
        const params = { ProductId: paramProductId };
  
        ajaxGetProducts(params)
          .then((res) => {
            const { data } = res;
            setProduct(data);
          })
          .catch(() => {
            setProduct({});
          });

        if ( !isShippingView ) {
          ajaxGetProductImageUrl({ ProductId: paramProductId })
            .then((res) => {
              setProductImgUrl(res);
            });
        }
      }
    }
  }, [paramProductId, props.add, isShippingView]);

  const validateFields = useCallback(() => {
    const fields = { ...product };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [product, allFields, requiredFields, floatFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = (duplicate) => () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;

    const params = { ...product };
    let url = '/Products';

    if(duplicate){
      delete params.id;
    }
    setIsSaving(true);
    ajaxSaveProduct(params)
      .then(() => {
        setIsSaving(false);
        navigate(url);
      })
      .catch(() => {
        setIsSaving(false);
        navigate(url);
      });
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    setProduct({ ...product, [key]: value, isNewImg: true });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;

    setProduct({ ...product, [key]: value });
  };

  const handleDownloadImage = () => {
    setIsProcessingImg(true);
    downloadFile({
      params: {
        id: product.id,
        fileType: 'product/product_image'
      },
      fileName: product.product_image,
      fileExt: 'png'
    }).then(() => {
      setIsProcessingImg(false);
    }).catch(() => {
      setIsProcessingImg(false);
    });
  };

  const handleDeleteImage = () => {
    setIsProcessingImg(true);
    ajaxDeleteProductImage({ProductId: product.id}).then((res) => {
      let copyProduct = { ...product };
      copyProduct.product_image = res.data.product_image;
      copyProduct.isNewImg = true;
      setProduct(copyProduct);
      setIsProcessingImg(false);
    }).catch(() => {
      setIsProcessingImg(false);
    });
  };

  const handleEnable = () => {
    setIsEnableDisable(true);
    ajaxEnableProduct({ ProductId: product.id })
      .then((res) => {
        setProduct({...product, active: res.data.active });
        setIsEnableDisable(false);
      })
      .catch((e) => {
        console.log(e.message);
        setIsEnableDisable(false)
      });
  };

  const handleDisable = () => {
    setIsEnableDisable(true);
    ajaxDisableProduct({ ProductId: product.id })
      .then((res) => {
        setProduct({...product, active: res.data.active });
        setIsEnableDisable(false);
      })
      .catch((e) => {
        console.log(e.message);
        setIsEnableDisable(false)
      });
  };

  const handleOpenStatistic = () => {
    setOpenStatistic(true);
  };

  const handleCloseStatistic = () => {
    setOpenStatistic(false);
  };

  const getFieldValue = (key) => {
    return product && product[key] !== undefined && product[key] !== null ? product[key] : '';
  };

  const getFieldPlaceholder = (key) => '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : '';

  const checkFieldError = (key) => errors && errors[key];

  const style = {
    button: {
      height: '40px',
      width: '150px',
      fontSize: '12px'
    },
    bgImg: {
      width: '100%',
      maxHeight: '350px',
      objectFit: 'contain'
    }
  };

  return (
    <React.Fragment>
      <Page title="Add / Edit Product">
        <StyledCard>
          <StyledCardFormContent>
            {
              !props.add && !isShippingView &&
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '25px', mt: '-20px' }}>
                <Link onClick={handleOpenStatistic}>
                  View Statistic
                </Link>
              </Box>
            }
            <StyledMainGrid columnSpacing>
              {allFields.map((entry) => (
                <React.Fragment key={entry['key']}>
                  {
                    (entry['type'] === 'text' || entry['type'] === 'password') &&
                    <Grid item xs={12} {...entry['gridProps']}>
                      <TextField
                        label={entry['label']}
                        size="small"
                        type={entry['type']}
                        name={entry['key']}
                        value={getFieldValue(entry['key'])}
                        onChange={handleFieldChange}
                        error={checkFieldError(entry['key'])}
                        helperText={getFieldErrorText(entry['key'])}
                        placeholder={getFieldPlaceholder(entry['key'])}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        disabled={!!entry.disabled}
                        autoFocus={!!entry.autoFocus}
                        {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                      />
                    </Grid>
                  }
                  {
                    entry['type'] === 'list' &&
                    <Grid item xs={12} {...entry['gridProps']}>
                      <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                        <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                        <Select
                          labelId={`select-helper-label-${entry['key']}`}
                          label={entry['label']}
                          type={entry['type']}
                          name={entry['key']}
                          size="small"
                          value={getFieldValue(entry['key'])}
                          onChange={(e) => handleFieldChange(e)}
                          sx={{ width: '100%' }}
                        >
                          { (entry.dataset || []).map( c =>
                            <MenuItem value={c.value}>{c.label}</MenuItem>
                          )}
                        </Select>
                        <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {
                    entry['type'] === 'checkbox' &&
                    <Grid item xs={12} {...entry['gridProps']}>
                      <FormControlLabel
                        control={<Checkbox name={entry['key']} value={1} disabled={!!entry.disabled} />}
                        onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                        checked={!!parseInt(getFieldValue(entry['key'], 10))}
                        label={entry['label']}
                      />
                    </Grid>
                  }
                  {
                    entry['type'] === 'file' &&
                    <Grid item xs={12} {...entry['gridProps']}>
                      <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                        <FileUploader
                          error={checkFieldError(entry['key'])}
                          accept={entry['accept']}
                          name={entry['key']}
                          label={entry['label']}
                          icon={<AttachFileIcon />}
                          handleChange={handleUploadFile}
                          disabled={!!entry.disabled}
                        />
                        <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                </React.Fragment>
              ))}
              {
                !props.add && product.product_image && !product.isNewImg && !isShippingView &&
                <React.Fragment>
                  <Grid item xs={12}>
                    <Box sx={{ mb: '10px' }}>
                      <Button variant="contained" size="normal" style={style.button} disabled={isProcessingImg} sx={{ mr: '20px' }} onClick={handleDownloadImage}>
                        Download Image
                      </Button>
                      <ConfirmationButton
                        buttonProps={{ disabled: isProcessingImg, variant: 'contained', size: 'normal', style: style.button, sx: { backgroundColor: 'red' } }}
                        handleDialogClickYes={handleDeleteImage}
                        buttonText="Delete Image"
                        dialogText="Are you sure you want to delete the product image?"
                        dialogYesText="Confirm"
                        dialogNoText="Cancel"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ width: '50%' }}>
                      <img style={style.bgImg} alt="bg" src={productImgUrl} />
                    </Box>
                  </Grid>
                </React.Fragment>
              }
              <Grid item xs={12} />
              {
                !props.add && !isShippingView &&
                <React.Fragment>
                  <Grid item xs={12}>
                    {
                      !!product.active ?
                      <Button variant="contained" size="normal" disabled={isEnableDisable} style={style.button} color="error" onClick={handleDisable}>
                        Disable Product
                      </Button>
                      :
                      <Button variant="contained" size="normal" disabled={isEnableDisable} style={style.button} sx={{ backgroundColor: 'green' }} onClick={handleEnable}>
                        Enable Product
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12} />
                </React.Fragment>
              }
              <Grid item xs={12}>
                <Button variant="contained" size="normal" disabled={isSaving} style={style.button} sx={{ backgroundColor: 'green', mr: '20px'}} onClick={handleSave(false)}>
                  Save Product
                </Button>
                {
                  paramProductId !== -1 && !isShippingView &&
                  <ConfirmationButton
                    buttonProps={{
                      variant: 'contained',
                      size: 'normal',
                      sx: { backgroundColor: 'blue', mr: '20px' },
                      style: style.button
                    }}
                    handleDialogClickYes={handleSave(true)}
                    buttonText={ "Duplicate" }
                    dialogText={ "Are you sure you want to duplicate the product?" }
                    dialogYesText="Confirm"
                    dialogNoText="Cancel"
                  />
                }
                <Button variant="outlined" size="normal" style={style.button} component={Link} to="/Products">
                  Cancel
                </Button>
              </Grid>
            </StyledMainGrid>
          </StyledCardFormContent>
        </StyledCard>
      </Page>
      { !props.add && <ProductStatisticDialog open={openStatistic} onClose={handleCloseStatistic} product={product}/> }
    </React.Fragment>
  );
}
