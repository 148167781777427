import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxGetDealersUrl = `${constants.ajax.baseUrl}/dealers`;

const ajaxGetDealers = (params) =>
  new Promise((res, rej) => {
    if (params && params.DealerId) {
      ajaxCall(ajaxGetDealersUrl + `/${params.DealerId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetDealersUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetDealersAutocomplete = () =>
  new Promise((res, rej) => {
    ajaxCall(ajaxGetDealersUrl + '/autocomplete', formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  });

const ajaxGetDealerCredit = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxGetDealersUrl + `/credit/${params.DealerId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxSaveDealer = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxGetDealersUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

export {
  ajaxGetDealers,
  ajaxSaveDealer,
  ajaxGetDealerCredit,
  ajaxGetDealersAutocomplete
};
