import React, {useMemo, useState, useRef, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import SortIcon from '@mui/icons-material/Sort';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

export default function StyledList({ dataSource, renderItem, addRow, addRowButton, onAdd, noEmptyRow, sort, pagination, ...restProps }) {
  const anchorSortRef = useRef(null);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortState, setSortState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    if ( sort ) {
      setSortState({
        sortKey: sort.sortKey,
        sort: sort.sort || 'asc'
      });
    }
  }, [sort]);

  const sortParams = useMemo(() => {
    if ( !sort ) {
      return false;
    }

    let returnSortParams = {};
    if ( sortState && sortState.sortKey ) {
      returnSortParams.sortKey = sortState.sortKey;
    }
    if ( sortState && sortState.sort ) {
      returnSortParams.sort = sortState.sort;
    }

    if ( Object.keys(returnSortParams).length <= 0 ) {
      return false;
    }

    let filterSort = sort.sortOptions.filter(s => s.key === returnSortParams.sortKey);
    if ( filterSort.length <= 0 ) {
      return false;
    }
    return {...returnSortParams, label: filterSort[0].label};
  },
  [sort, sortState]);

  const onSortOpen = () => {
    setSortOpen((prevOpen) => !prevOpen);
  };

  const onSortClose = (event) => {
    if (
      anchorSortRef.current &&
      anchorSortRef.current.contains(event.target)
    ) {
      return;
    }

    setSortOpen(false);
  };

  const onSortClick = (sortParams) => {
    setSortState({...sortParams});
    setSortOpen(false);
  };

  const onPageChange = (event, value) => {
    setPageIndex(value);
  };

  let displayDataSource = dataSource ? [...dataSource] : [];
  if ( sortParams ) {
    displayDataSource = displayDataSource.sort((a, b) => {
      if ( a[sortParams.sortKey] > b[sortParams.sortKey] ) {
        return sortParams.sort === 'desc' ? -1 : 1;
      }
      else if ( a[sortParams.sortKey] < b[sortParams.sortKey] ) {
        return sortParams.sort === 'desc' ? 1 : -1;
      }
      return 0;
    });
  }

  let pageCount = 1;
  let pageItemCount = 25;
  if ( pagination ) {
    pageItemCount = parseInt(pagination, 10);
    if ( isNaN(pageItemCount) ) {
      pageItemCount = 25;
    }
    pageCount = Math.round((displayDataSource.length + 0.5) / pageItemCount) || 1; // round up
    displayDataSource = displayDataSource.slice(((pageIndex - 1) * pageItemCount), pageIndex * pageItemCount)
  }

  const secondarySx = { fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' };
  return (
    <React.Fragment>
      {
        sortParams &&
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <ButtonGroup ref={anchorSortRef} aria-label="split button">
            <Tooltip title="Sort A to Z">
              <Button
                size="small"
                onClick={() => onSortClick({...sortParams, sort: 'asc'})}
              >
                <SortIcon sx={{ transform: 'rotate(180deg)'}} color={sortParams.sort === 'asc' ? 'info' : undefined}/>
              </Button>
            </Tooltip>
            <Tooltip title="Sort Z to A">
              <Button
                size="small"
                onClick={() => onSortClick({...sortParams, sort: 'desc'})}
              >
                <SortIcon color={sortParams.sort === 'desc' ? 'info' : undefined}/>
              </Button>
            </Tooltip>
            <Button
              size="small"
              aria-controls={sortOpen ? 'split-button-menu' : undefined}
              aria-expanded={sortOpen ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={onSortOpen}
            >
              {sortParams.label}
            </Button>
            <Button
              size="small"
              aria-controls={sortOpen ? 'split-button-menu' : undefined}
              aria-expanded={sortOpen ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={onSortOpen}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Box>
      }
      <List {...restProps}>
        {
          addRow &&
          (
            typeof addRow === 'function' ?
            addRow()
            :
            typeof addRow === 'object' ?
            addRow
            :
            <React.Fragment>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    addRowButton ?
                    addRowButton
                    :
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      sx={{ margin: '10px' }}
                      startIcon={<AddIcon />}
                      onClick={onAdd}
                    >
                      New
                    </Button>
                  }
                />
              </ListItem>
              {
                displayDataSource &&
                displayDataSource.length > 0 &&
                <Divider />
              }
            </React.Fragment>
          )
        }
        {
          displayDataSource &&
          displayDataSource.length > 0 &&
          displayDataSource.map((data, idx) => {
            let content = data.toString();
            if ( typeof renderItem === 'function' ) {
              content = renderItem(data, idx, secondarySx);
            }
            if ( typeof content === 'string' || typeof content === 'number' ) {
              return (
                <ListItem key={idx} disablePadding>
                  <ListItemButton>
                    <ListItemText primary={content.toString()} />
                  </ListItemButton>
                </ListItem>
              );
            }
            return content;
          }).reduce((sum, cur, idx, arr) => ([...sum, cur, ...(idx < arr.length -1 || addRow ? [<Divider key={`divider${idx}`} />] : [])]), [])
        }
        {
          (!displayDataSource || displayDataSource.length <= 0) && !addRow && !noEmptyRow &&
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="No Items" />
            </ListItemButton>
          </ListItem>
        }
      </List>
      {
        pagination &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination page={pageIndex} count={pageCount} onChange={onPageChange} color="secondary" />
        </div>
      }
      <Popper
        open={sortOpen}
        anchorEl={anchorSortRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onSortClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {sort.sortOptions.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => onSortClick({...sortState, sortKey: option.key})}
                    >
                      {
                        option.key === sortParams.sortKey ?
                        <React.Fragment>
                          <ListItemIcon>
                            <CheckIcon color="info"/>
                          </ListItemIcon>
                          {option.label}
                        </React.Fragment>
                        :
                        <ListItemText inset>{option.label}</ListItemText>
                      }
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
