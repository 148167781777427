import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import StyledCard from '../../components/StyledCard'
import StyledList from '../../components/StyledList';
import { toDateText, copyToClipboard } from '../../helpers';

export default function ProductionShipmentToday({ orders }) {
  const pendingOrders = useMemo(() => {
    return orders.filter(o => o.shipping_status === 'done' && o.shipping_for_today === 1);
  }, [orders]);

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box
            component={RouterLink}
            to={'/orders/edit/' + data.id}
            sx={{
              ...secondarySx,
              cursor: 'pointer',
              textDecoration: 'none'
            }}
          >
            {toDateText(data.date)}
          </Box>
        }
      >
        <ListItemButton
          component={RouterLink}
          to={'/orders/edit/' + data.id + '?page=dashboard'}
        >
          <ListItemText
            primary={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  "& .copy": {
                    display: 'none',
                    ml: '10px',
                    height: '20px',
                    marginTop: '-5px'
                  },
                  "&:hover .copy": {
                    display: 'block'
                  }
                }}>
                <Box>
                  {data.oc_ref}
                </Box>
                <Box className="copy">
                  <Tooltip title={`Copy '${data.oc_ref}' to clipboard`}>
                    <IconButton
                      size="small"
                      sx={{ fontSize: '1rem' }}
                      onClick={(e) => {
                        copyToClipboard(data.oc_ref);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ContentCopyIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <StyledCard
      title="Shipments for Today"
      titleIcon={PendingActionsIcon}
    >
      <CardContent>
        <StyledList dataSource={pendingOrders} renderItem={renderItem} dense/>
      </CardContent>
    </StyledCard>
  );
}
