import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;

const getAdminUsersUrl = `${constants.ajax.baseUrl}/admin/users`;

const ajaxGetUsers = (params) =>
  new Promise((res, rej) => {
    if (params.UserId) {
      ajaxCall(getAdminUsersUrl + `/${params.UserId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminUsersUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveUser = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminUsersUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDisableUser = (params) =>
  new Promise((res, rej) => {
    if (params.UserId) {
      ajaxCall(getAdminUsersUrl + `/disable/${params.UserId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxEnableUser = (params) =>
  new Promise((res, rej) => {
    if (params.UserId) {
      ajaxCall(getAdminUsersUrl + `/enable/${params.UserId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxDeleteUser = (params) =>
  new Promise((res, rej) => {
    if (params.UserId) {
      ajaxCall(getAdminUsersUrl + `/delete/${params.UserId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

export {
  ajaxGetUsers,
  ajaxSaveUser,
  ajaxDisableUser,
  ajaxEnableUser,
  ajaxDeleteUser
};
