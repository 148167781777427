import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell  from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import StyledSearchDate from './StyledSearchDate';
import LoadingSpinner from './LoadingSpinner';
import { ajaxGetSoldProductsCount } from '../services/productService';
import { toDateText} from '../helpers';

export default function ProductStatisticDialog(props) {
  const { open, onClose, product } = props

  const [loading, setLoading] = useState(false);
  const [soldProducts, setSoldProducts] = useState([]);
  const [startDate, setStartDate] = useState(moment().add(-1, 'M').startOf('day').unix());
  const [endDate, setEndDate] = useState(moment().endOf('day').unix());

  useEffect(() => {
    if(open) {
      setLoading(true)
      ajaxGetSoldProductsCount({ProductId: product.id, StartDate: startDate, EndDate: endDate})
      .then((res) => {
        setSoldProducts(res.data)
        setLoading(false)
      })
      .catch(() => {
        setSoldProducts([])
        setLoading(false)
      });
    }
    
  }, [open, startDate, endDate, product.id]);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{product.product_code}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} item sx={{ mt: '6px' }}>
            <StyledSearchDate
              startDate={startDate}
              endDate={endDate}
              onStartDate={setStartDate}
              onEndDate={setEndDate}
              gridProps={{ columnSpacing: 1 }}
            />
          </Grid>
          <Grid xs={12} item sx={{ minHeight: '80px', position: 'relative' }}>
            { loading ?
              <LoadingSpinner /> : 
              <div style={{ marginTop:"12px" }}>
                <strong>The total sales of this product is {soldProducts.reduce((a, v)=> a + v.quantity, 0)}</strong>
                {
                  soldProducts.length > 0 &&
                  <TableContainer sx={{ maxHeight: 400, mt: "12px" }}>
                    <Table stickyHeader size="small" aria-label="sticky a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Order Date</TableCell>
                          <TableCell>Dealer</TableCell>
                          <TableCell align='right'>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {soldProducts.map((p,i)=>
                          <TableRow>                          
                            <TableCell>{toDateText(p.date)}</TableCell>
                            <TableCell>{p.dealer}</TableCell>
                            <TableCell align='right'>{p.quantity}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </div>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}