import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ajaxGetOverdueInvoices } from '../../services/reportsService';
import useAlert from '../../hooks/useAlert'
import moment from 'moment';


export default function OverdueInvoices() {
  
  const [isProcessing, setIsProcessing] = useState(false);
  const errorAlert = useAlert(false, 'error');

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    let filename = `overdue-invoices_${moment().format('YYYY-MM-DD')}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    
    ajaxGetOverdueInvoices({ format }, filename)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        errorAlert.setAlert(e.message);
        setIsProcessing(false);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 20px 10px 0' }}
          onClick={handleDownload('pdf')}
          disabled={isProcessing}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in PDF
        </Button>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          onClick={handleDownload('csv')}
          disabled={isProcessing}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in CSV
        </Button>
      </Grid>
    </Grid>
  );
}
