import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { ajaxGetMonthlySales } from '../../services/reportsService';
import useAlert from '../../hooks/useAlert'
import moment from 'moment';

export default function MonthlySales() {
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [date, setDate] = useState(moment());
  const [readyToDownload, setReadyToDownload] = useState(false);
  const errorAlert = useAlert(false, 'error');

  useEffect(() => {
    setReadyToDownload(date !== null);
  }, [date]);

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    let filename = `monthly-sales_${date.format('YYYY')}-${date.format('MM')}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    
    ajaxGetMonthlySales({ format, month: date.format('M'), year: date.format('YYYY') }, filename)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        errorAlert.setAlert(e.message);
        setIsProcessing(false);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DateCalendar
          disableFuture
          views={['year', 'month']}
          openTo="month"
          onMonthChange={setDate}
          onYearChange={setDate}
        />
      </Grid> 
      <Grid item xs={12}>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 20px 10px 0' }}
          onClick={handleDownload('pdf')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in PDF
        </Button>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          onClick={handleDownload('csv')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in CSV
        </Button>
      </Grid>
    </Grid>
  );
}
