import React, { useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { AuthContext } from '../context';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1 + '!important'
  },
  logo: {
    height: '40px',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '10px'
  }
}));

export default function Header(props) {
  const { isUserLogged } = useContext(AuthContext);
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          { isUserLogged &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => props.toggleMenu()}
            >
              <MenuIcon />
            </IconButton>
          }
          <Grid container direction="row" alignItems="center" rowSpacing={0} spacing={0} columnSpacing={0}>
            <Grid item alignItems="center">
              <Link to="/orders">
                <Box className={classes.logo} component="img" src="/akcp.png" alt="logo" />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
