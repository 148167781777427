import React, { useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Page from '../../components/Page';
import StyledMainGrid from '../../components/StyledMainGrid';
import StyledCardFormContent from '../../components/StyledCardFormContent';
import StyledCard from '../../components/StyledCard'
import DealerStatementOfAccounts from './DealerStatementOfAccounts';
import OverdueInvoices from './OverdueInvoices';
import ShippingCost from './ShippingCost';
import MonthlySales from './MonthlySales';
import TopDealers from './TopDealers';
import inMemoryUser from '../../services/inMemoryUser';


export default function Reports() {
  const [report, setReport] = useState();

  const allReports = useMemo(
    () => {
      if (inMemoryUser.isAdmin()) {
        return [
          {
            key: 'dealer-statement-of-accounts',
            label: 'Dealer statement of accounts',
            component: DealerStatementOfAccounts
          },
          {
            key: 'overdue-invoices',
            label: 'Overdue Invoices',
            component: OverdueInvoices
          },
          {
            key: 'shipping-cost',
            label: 'Shipping Cost',
            component: ShippingCost
          },
          {
            key: 'monthly-sales',
            label: 'Monthly Sales',
            component: MonthlySales
          },
          {
            key: 'top-dealers',
            label: 'Top Dealers',
            component: TopDealers
          }
        ];
      } else if (inMemoryUser.isSale()) {
        return [
          {
            key: 'dealer-statement-of-accounts',
            label: 'Dealer statement of accounts',
            component: DealerStatementOfAccounts
          },
          {
            key: 'overdue-invoices',
            label: 'Overdue Invoices',
            component: OverdueInvoices
          },
          {
            key: 'monthly-sales',
            label: 'Monthly Sales',
            component: MonthlySales
          },
          {
            key: 'top-dealers',
            label: 'Top Dealers',
            component: TopDealers
          }
        ];
      } else if (inMemoryUser.isShipping()) {
        return [
          {
            key: 'shipping-cost',
            label: 'Shipping Cost',
            component: ShippingCost
          }
        ];
      }
    },
    []
  );

  const handleSelectReport = (event) => {
    setReport(event.target.value);
  };

  const cmp = allReports.find((r)=>r.key===report);
  const reportComponent = cmp ? { type: cmp.component } : null;

  return (
    <Page title="Reports">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="report-select-label">Select report</InputLabel>
                <Select
                  labelId="report-select-label"
                  id="report-select"
                  value={report}
                  label="Select report"
                  onChange={handleSelectReport}
                >
                  {allReports.map((r) => (
                    <MenuItem key={r.key} value={r.key}>{r.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {reportComponent && <reportComponent.type/>}
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
