import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { ajaxGetTopDealers } from '../../services/reportsService';
import useAlert from '../../hooks/useAlert'
import moment from 'moment-timezone';

export default function TopDealers() {
  const [isProcessing, setIsProcessing] = useState(false);
  const startOfCurrentMonth = moment().startOf('month');
  const [datetime, setDatetime] = useState(startOfCurrentMonth.unix());
  const [readyToDownload, setReadyToDownload] = useState(false);
  const errorAlert = useAlert(false, 'error');

  useEffect(() => {
    setReadyToDownload(datetime !== null);
  }, [datetime]);

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    const tz = moment.tz.guess();
    let startDate, endDate;
    if ( datetime < 0 ) {
      startDate = moment().subtract((datetime * -1) - 1, 'months').startOf('month').unix();
      endDate = moment().endOf('month').unix()
    } else {
      startDate = datetime;
      endDate = moment.unix(datetime).endOf('month').unix()
    }

    let filename = `top-dealers_${moment.unix(startDate).format('YYYY-MM-DD')}_${moment.unix(endDate).format('YYYY-MM-DD')}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    
    ajaxGetTopDealers({ format, startDate, endDate, tz }, filename)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        errorAlert.setAlert(e.message);
        setIsProcessing(false);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="outlined">
          <Select
            size="small"
            value={datetime}
            onChange={(e) => setDatetime(e.target.value)}
          >
            <MenuItem value={-3}>Latest 3 Months</MenuItem>
            <MenuItem value={-6}>Latest 6 Months</MenuItem>
            <MenuItem value={-12}>Latest 12 Months</MenuItem>
            <Divider />
            {
              (new Array(12)).fill(0).map((e, idx) => {
                let d = moment(startOfCurrentMonth).subtract(idx, 'months').startOf('month');
                return <MenuItem value={d.unix()}>{d.format('MMMM YYYY')}</MenuItem>;
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 20px 10px 0' }}
          onClick={handleDownload('pdf')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in PDF
        </Button>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          onClick={handleDownload('csv')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in CSV
        </Button>
      </Grid>
    </Grid>
  );
}
