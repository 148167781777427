import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import inMemoryUser from '../../services/inMemoryUser';
import {
  ajaxGetQuotations,
  ajaxSaveQuotation,
  ajaxDeleteQuotation,
  ajaxTurnQuotationToOrder
} from '../../services/orderService';
import {
  ajaxRequestToDeleteQuotation,
  ajaxСancelRequestToDeleteQuotation
} from '../../services/saleOrderService';
import {
    ajaxGetQuotations as ajaxGetQuotationsProduction,
    ajaxSaveQuotation as ajaxSaveQuotationProduction,
    ajaxTurnQuotationToOrder as ajaxTurnQuotationToOrderProduction
} from '../../services/productionOrderService';
import { ajaxGetQuotations as ajaxGetQuotationsShipping } from '../../services/shippingOrderService';
import { ajaxGetDealersAutocomplete } from '../../services/dealerService';
import StyledMainGrid from '../../components/StyledMainGrid';
import UtcDatePicker from '../../components/UtcDatePicker';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import FileUploader from '../../components/FileUploader';
import ConfirmationButton from '../../components/ConfirmationButton';
import OrderProducts from '../../components/OrderProducts';
import { toDate, isFloat, calcTotalPrice, countries, noty } from '../../helpers';
import moment from 'moment';

export default function Quotation(props) {
  const incomingParams = useParams();

  const [searchParams] = useSearchParams();

  const [quotation, setQuotation] = useState({
    shipping_status: 'wait',
    products: [],
    date: moment().hour(0).minute(0).second(0).millisecond(0).unix() + (moment().utcOffset() * 60)
  });
  const [dealers, setDealers] = useState([]);
  const [dealerChanged, setDealerChanged] = useState(false);
  const [productChanged, setProductChanged] = useState(false);
  const [backUrl, setBackUrl] = useState('/quotations');

  const paramQuotationId = (incomingParams && incomingParams.QuotationId ? incomingParams.QuotationId : -1);

  useEffect(() => {
    if(searchParams.get("page") === 'dashboard') {
      setBackUrl('/dashboard')  
    } else {
      setBackUrl('/quotations') 
    }
  }, [searchParams]);

  const allFields = useMemo(
    () => {
      const updateQuotationProducts = (quotationProducts) => {
        setQuotation(quotation=> ({...quotation, products: quotationProducts}));
        setProductChanged(true);
      };
      const dealer = (dealers || []).find(d => d.id === quotation.dealer)
      return [
        {
          key: 'dealer',
          type: 'autocomplete',
          label: 'Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name}))
        },
        {
          key: 'billing_country',
          type: 'list',
          label: 'Billing Country',
          dataset: countries.map(c => ({ value: c.name, label: c.name })),
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_country',
          type: 'list',
          label: 'Shipping Country',
          dataset: countries.map(c => ({ value: c.name, label: c.name })),
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_company',
          type: 'autocomplete',
          label: 'Billing Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name})),
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_company',
          type: 'autocomplete',
          label: 'Shipping Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name})),
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_address',
          type: 'text',
          label: 'Billing Address',
          gridProps: { xs: 6 },
          multiline: true
        },
        {
          key: 'shipping_address',
          type: 'text',
          label: 'Shipping Address',
          gridProps: { xs: 6 },
          multiline: true
        },
        {
          key: 'billing_vat',
          type: 'text',
          label: 'Billing VAT',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_vat',
          type: 'text',
          label: 'Shipping VAT',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_email',
          type: 'text',
          label: 'Billing Email',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_email',
          type: 'text',
          label: 'Shipping Email',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_phone',
          type: 'text',
          label: 'Billing Phone Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_phone',
          type: 'text',
          label: 'Shipping Phone Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_mobile',
          type: 'text',
          label: 'Billing Mobile Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_mobile',
          type: 'text',
          label: 'Shipping Mobile Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'customer_ref',
          type: 'text',
          label: 'Customer Reference',
          gridProps: { xs: 6 },
          divider: true
        },
        {
          key: 'products',
          type: 'component',
          component: OrderProducts,
          componentProps: {
            dealerDiscount: dealer ? dealer.discount || 0 : 0,
            dealerChanged: dealerChanged,
            orderProducts: quotation.products,
            updateOrderProducts: updateQuotationProducts
          }
        },
        {
          key: 'invoice_note',
          type: 'text',
          label: 'OC/Invoice Note',
          divider: true,
          multiline: true,
          lineNum: 15
        },
        {
          key: 'date',
          type: 'date',
          label: 'Quotation Date',
          gridProps: { xs: 12, sm: 6 }
        },
        {
          key: 'value',
          type: 'dollar',
          label: 'Quotation Value',
          gridProps: { xs: 12, sm: 6 },
          disabled: true
        },
        {
          key: 'quote_ref',
          type: 'text',
          label: 'Quotation Ref.',
          gridProps: { xs: 12, sm: 4 },
          disabled: true
        },
        {
          key: 'oc_ref',
          type: 'text',
          label: 'OC Ref.',
          gridProps: { xs: 12, sm: 4 },
          // autoFocus: true
          disabled: true
        },
        {
          key: 'invoice',
          type: 'text',
          label: 'Invoice No.',
          gridProps: { xs: 12, sm: 4 },
          disabled: true
        },
        {
          key: 'note',
          type: 'text',
          label: 'Note',
          divider: true,
          multiline: true
        }
      ]
    },
    [dealers, quotation.dealer, quotation.products, dealerChanged]
  );
  

  const requiredFields = useMemo(() => ['dealer', 'date', 'value', ...(quotation.shipping_status === 'done' ? ['shipping_awb'] : [])], [quotation.shipping_status]);

  const emailFields = useMemo(() => ['email'], []);

  const floatFields = useMemo(() => ['value', 'shipping_cost', 'actual_shipping_cost', 'payment_advance', 'payment_full', 'payment_received', 'outstanding', 'outstanding_shipped'], []);

  const [errors, setErrors] = useState({});
  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    ajaxGetDealersAutocomplete({})
      .then((res) => {
        const { data } = res;
        setDealers(data);
      })
      .catch(() => {
        setDealers([]);
      });
  }, []);

  useEffect(() => {
    if (!props.add) {
      if (paramQuotationId !== -1) {
        const params = { OrderId: paramQuotationId };

        let getQuotations = ajaxGetQuotations;
        if(inMemoryUser.isShipping()) {
          getQuotations = ajaxGetQuotationsShipping;
        } else if(inMemoryUser.isProduction()) {
          getQuotations = ajaxGetQuotationsProduction;
        }
  
        getQuotations(params)
          .then((res) => {
            const { data } = res;
            setQuotation(data);
          })
          .catch(() => {
            setQuotation({});
          });
      }
    }
  }, [paramQuotationId, props.add]);

  const dealerId = quotation ? quotation.dealer : null;
  useEffect(() => {
    const dealer = (dealers || []).find(d => d.id === dealerId)
    if(dealerChanged && dealer) {
      setQuotation(quotation=>({
        ...quotation,
        billing_country: dealer.country || '',
        billing_company: dealer.id || '',
        billing_address: dealer.billing_address,
        billing_vat: dealer.billing_vat,
        billing_email: dealer.billing_email,
        billing_phone: dealer.billing_phone,
        billing_mobile: dealer.billing_mobile,
        shipping_country: dealer.country || '',
        shipping_company: dealer.id || '',
        shipping_address: dealer.same_address ? dealer.billing_address : dealer.shipping_address,
        shipping_vat: dealer.same_address ? dealer.billing_vat : dealer.shipping_vat,
        shipping_email: dealer.same_address ? dealer.billing_email : dealer.shipping_email,
        shipping_phone: dealer.same_address ? dealer.billing_phone : dealer.shipping_phone,
        shipping_mobile:dealer.same_address ? dealer.billing_mobile :  dealer.shipping_mobile,
        invoice_note: dealer.order_notes
      }));
    }
  }, [dealers, dealerId, dealerChanged]);

  useEffect(() => {
    if(productChanged) {
      setQuotation(quotation=>({
        ...quotation,
        value: quotation.products.reduce((acc, p) => acc + calcTotalPrice(p), 0),
        shipping_cost: quotation.products.reduce((acc, p) => acc + (p.is_shipping_cost ? calcTotalPrice(p) : 0), 0)
      }));
    }
  }, [productChanged, quotation.products]);

  const validateFields = useCallback(() => {
    const fields = { ...quotation };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [quotation, allFields, requiredFields, emailFields, floatFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = (revise, showNoty = true) => () => {
    setSubmitOnce(true);
    setIsSaving(true);
    if (!validateFields()) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    let saveQuotation = ajaxSaveQuotation;
    if(inMemoryUser.isProduction()) {
      saveQuotation = ajaxSaveQuotationProduction;
    }

    const params = { ...quotation, ...!!revise ? {revise: true} : {} };
    saveQuotation(params)
      .then((res) => {
        if(showNoty){
          if (res.error) {
            noty(res.error, 'error');
          } else {
            noty('Save successfully', 'success');
          }
        }
        setQuotation(res.data);
        setIsSaving(false)
      })
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        setIsSaving(false);
        console.log(e.message)
      });
  };

  const handleDuplicate = (revise) => () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    let saveQuotation = ajaxSaveQuotation;
    if(inMemoryUser.isProduction()) {
      saveQuotation = ajaxSaveQuotationProduction;
    }

    const params = { ...quotation, ...!!revise ? {revise: true} : {} };

    delete params.id;
    delete params.invoice;
    delete params.oc_ref;

    saveQuotation(params)
      .then((res) => navigate(backUrl))
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        console.log(e.message)
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
        key = typeof event === 'string' ? event : event.target.name,
        field = allFields.find(f=>f.key === key);

    if ( key === 'dealer' &&  value !==null && quotation && quotation.dealer !== value ) {
      setDealerChanged(true);
    }
    
    if ( field && field.type === 'autocomplete') {
      setQuotation({ ...quotation, [key]: value ? value.value : null });
    } else if ( key === 'shipping_status' && value === 'done' ) {
      setQuotation({
        ...quotation,
        shipping_status: value,
        shipping_for_today: 0
      });
    }
    else if ( key === 'shipping_awb' && !!value ) {
      setQuotation({
        ...quotation,
        shipping_awb: value,
        shipping_status: 'done',
        shipping_for_today: 0
      });
    }
    else {
      setQuotation({ ...quotation, [key]: value });
    }
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    let filename = value.name.replace('.pdf', '');
    let newQuotation = {
      ...quotation,
      [key]: value
    };
    if ( key === 'oc_pdf' ) {
      newQuotation = {
        ...newQuotation,
        oc_ref: filename
      };
    }
    else if ( key === 'invoice_pdf' ) {
      newQuotation = {
        ...newQuotation,
        invoice: filename
      };
    }
    setQuotation(newQuotation);
  };

  const handleTurnToOrder = (id) => {
    let turnQuotationToOrder = ajaxTurnQuotationToOrder;
    if(inMemoryUser.isProduction()) {
      turnQuotationToOrder = ajaxTurnQuotationToOrderProduction;
    }
    turnQuotationToOrder({ OrderId: id })
      .then(() => navigate(backUrl))
      .catch((e) => console.log(e.message));
  };

  const handleQuotationDelete = (id) => {
    ajaxDeleteQuotation({ OrderId: id })
      .then(() => navigate(backUrl))
      .catch((e) => console.log(e.message));
  };

  const handleQuotationRequestToDelete = (id) => {
    let ajaxMethod = ajaxRequestToDeleteQuotation
    if (quotation.request_to_delete) {
      ajaxMethod = ajaxСancelRequestToDeleteQuotation;
    }

    ajaxMethod({ OrderId: id })
      .then(() => navigate(backUrl))
      .catch((e) => console.log(e.message));
  }

  const getFieldValue = (key) => quotation && quotation[key] !== undefined && quotation[key] !== null ? quotation[key] : '';

  const getFieldPlaceholder = (key) => key === 'password' && !props.add ? 'leave blank to not change the password' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : (duplicateErrors && duplicateErrors[key] ? duplicateErrors[key] : '');

  const checkFieldError = (key) => (errors && errors[key]) || (duplicateErrors && duplicateErrors[key]);

  const buttonStyle = {
    height: '40px',
    width: '120px',
    fontSize: '12px'
  };

  return (
    <Page title="Add / Edit Quotation">
      <StyledCard>
        <StyledCardFormContent>
          {
            !props.add &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '25px', mt: '-20px' }}>
              <Link to={`/events/quotation/${quotation.id}`}>
                View History
              </Link>
            </Box>
          }
          <StyledMainGrid columnSpacing alignItems="center" >
            {allFields.map((entry, index) => (
              <React.Fragment key={entry['key']}>
                { (entry['type'] === 'component') && 
                  <Grid item xs={12} {...entry['gridProps']}>
                    <entry.component {...entry.componentProps} beforeRedirect={handleSave(false, false)}/>
                  </Grid>
                }
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: entry.lineNum ? entry.lineNum : 4 } : {}}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'dollar') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                      }}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'date') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <UtcDatePicker
                      label={entry['label']}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: checkFieldError(entry['key']),
                          helperText: getFieldErrorText(entry['key'])
                        },
                      }}
                      value={getFieldValue(entry['key']) ? moment(toDate(getFieldValue(entry['key']))).unix() : null}
                      onChange={(value) => handleFieldChange(entry['key'], value)}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'autocomplete' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <Autocomplete
                        sx={{ width: '100%' }}
                        size="small" 
                        value={(entry.dataset || []).find(o=>o.value === getFieldValue(entry['key'])) || null}
                        options={(entry.dataset || [])}
                        isOptionEqualToValue={(option, value) => value && option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        onChange={(_,v)=>handleFieldChange(entry['key'], v)}
                        renderInput={(params) => <TextField {...params} label={entry['label']}/>}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'list'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1}/>}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                      disabled={!!entry.disabled}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'file' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <FileUploader
                        error={checkFieldError(entry['key'])}
                        accept=".pdf"
                        name={entry['key']}
                        label={entry['label']}
                        icon={<AttachFileIcon />}
                        handleChange={handleUploadFile}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['divider'] && 
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12} />
            <Grid item xs={12} sm={9}>
              {
                !inMemoryUser.isShipping() &&
                <Button disabled={isSaving} variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave(false)}>
                  Save
                </Button>
              }
              {
                paramQuotationId !== -1 && !inMemoryUser.isShipping() &&
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'green', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={handleSave(true)}
                  buttonText={ "Revise" }
                  dialogText={ "Are you sure you want to revise the quotation?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              {
                paramQuotationId !== -1 && !inMemoryUser.isShipping() &&
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'blue', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={handleDuplicate(true)}
                  buttonText={ "Duplicate" }
                  dialogText={ "Are you sure you want to duplicate the quotation?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              {
                paramQuotationId !== -1 && !inMemoryUser.isShipping() &&  
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'blue', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={() => handleTurnToOrder(paramQuotationId)}
                  buttonText={ "Confirm Order" }
                  dialogText={ "Are you sure you want to turn the quotation into the order?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to={backUrl}>
                Back
              </Button>
            </Grid>
            {
              (paramQuotationId !== -1 && inMemoryUser.isAdmin()) && 
                <Grid item xs={12} sm={3} sx={{textAlign: "right"}}>
                  <ConfirmationButton
                    buttonProps={{
                      disabled: isSaving,
                      variant: 'contained',
                      size: 'medium',
                      sx: { backgroundColor: 'red' },
                      style: buttonStyle
                    }}
                    handleDialogClickYes={() => handleQuotationDelete(paramQuotationId)}
                    buttonText={ "Delete" }
                    dialogText={ "Are you sure you want to delete this quotation?" }
                    dialogYesText="Confirm"
                    dialogNoText="Cancel"
                  />
                </Grid>
            }
            {
              (paramQuotationId !== -1 && (inMemoryUser.isSale() || inMemoryUser.isProduction())) && 
              <Grid item xs={12} sm={3} sx={{textAlign: "right"}}>
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: !!quotation.request_to_delete ? 'orange' : 'red' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={() => handleQuotationRequestToDelete(paramQuotationId)}
                  buttonText={ quotation.request_to_delete ? "Cancel Request to Delete" : "Request to Delete" }
                  dialogText={ quotation.request_to_delete ? "Are you sure you want to cancel a request to delete this quotation?" : "Are you sure you want to request to delete this quotation? The administrator will review your request." }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              </Grid>
            }
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
