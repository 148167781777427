import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function PageHeader({ title, backButton }) {
  return (
    <Box sx={{ pt: '15px', pb: '15px' }}>
      <Typography variant={'h6'} color="inherit" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
        {backButton}
        {
          Array.isArray(title) &&
          title.map((t, idx) => (
            <React.Fragment key={idx}>
              {
                idx > 0 &&
                <ChevronRightIcon sx={{ ml: '5px', mr: '5px', mt: { xs: 0, sm: '3px' } }} fontSize={'medium'}/>
              }
              {t}
            </React.Fragment>
          ))
        }
        {
          !Array.isArray(title) &&
          title.toString()
        }
      </Typography>
    </Box>
  );
}
