import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ajaxGetShippingCost } from '../../services/reportsService';
import StyledSearchDate from '../../components/StyledSearchDate';
import useAlert from '../../hooks/useAlert'
import moment from 'moment';

export default function ShippingCost() {
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [company, setCompany] = useState('all');
  const [readyToDownload, setReadyToDownload] = useState(false);
  const errorAlert = useAlert(false, 'error');

  const companies = useMemo(
    () => [
      { value: 'all',                     label: 'All companies'},
      { value: 'DHL',                     label: 'DHL'},
      { value: 'FedEx',                   label: 'FedEx'},
      { value: 'UPS',                     label: 'UPS'},
      { value: 'TNT',                     label: 'TNT'},
      { value: 'other:DHL,FedEx,UPS,TNT', label: 'Other'},
    ],
    []
  );

  useEffect(() => {
    setReadyToDownload(startDate !== null && endDate !== null);
  }, [startDate, endDate]);

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    const companyName = company.startsWith('other') ? 'other' : company;
    let filename = `shipping-cost_${companyName}_${moment.unix(startDate).format('YYYY-MM-DD')}_${moment.unix(endDate).format('YYYY-MM-DD')}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    
    ajaxGetShippingCost({ format, company, startDate, endDate }, filename)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        errorAlert.setAlert(e.message);
        setIsProcessing(false);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="company-select-label">Select company</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={company}
            label="Select company"
            onChange={(event) => setCompany(event.target.value)}
          >
            {companies.map((r) => (
              <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> 
      <Grid item xs={12} sm={8}>
        <StyledSearchDate
          startDate={startDate}
          endDate={endDate}
          onStartDate={setStartDate}
          onEndDate={setEndDate}
          gridProps={{ columnSpacing: 1 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 20px 10px 0' }}
          onClick={handleDownload('pdf')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in PDF
        </Button>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          onClick={handleDownload('csv')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in CSV
        </Button>
      </Grid>
    </Grid>
  );
}
