import { ajaxCallBlob, formRequestData } from './ajaxService';

const constants = window.getConfig;

const getDealerStatementOfAccountsUrl = `${constants.ajax.baseUrl}/sale/reports/dealerStatementOfAccounts`;
const getShippingCostUrl = `${constants.ajax.baseUrl}/shipping/reports/shippingCost`;
const getOverdueInvoicesUrl = `${constants.ajax.baseUrl}/sale/reports/overdueInvoices`;
const getMonthlySalesUrl = `${constants.ajax.baseUrl}/sale/reports/monthlySales`;
const getTopDealersUrl = `${constants.ajax.baseUrl}/shipping/reports/topDealers`;


const ajaxGetDealerStatementOfAccounts = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getDealerStatementOfAccountsUrl + `/${params.format}/${params.dealerId}/${params.startDate}/${params.endDate}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  }).then((res) => {
    let url = window.URL.createObjectURL(res.blob);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });


const ajaxGetShippingCost = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getShippingCostUrl + `/${params.format}/${params.company}/${params.startDate}/${params.endDate}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  }).then((res) => {
    let url = window.URL.createObjectURL(res.blob);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxGetOverdueInvoices = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getOverdueInvoicesUrl + `/${params.format}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  }).then((res) => {
    let url = window.URL.createObjectURL(res.blob);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxGetMonthlySales = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getMonthlySalesUrl + `/${params.format}/${params.year}/${params.month}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  }).then((res) => {
    let url = window.URL.createObjectURL(res.blob);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

const ajaxGetTopDealers = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getTopDealersUrl + `/${params.format}/${params.startDate}/${params.endDate}/${window.btoa(params.tz)}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  }).then((res) => {
    let url = window.URL.createObjectURL(res.blob);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
  })
  .catch((error) => {
    console.log(error.message);
  });

export {
    ajaxGetDealerStatementOfAccounts,
    ajaxGetShippingCost,
    ajaxGetOverdueInvoices,
    ajaxGetMonthlySales,
    ajaxGetTopDealers
};

