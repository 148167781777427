import React, {useEffect, useState} from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';
import { ajaxGetDealerCredit } from '../../services/dealerService';

export default function AvailbleCredit({ dealerId }) {
  const [credit, setCredit] = useState({ used: 0, limit: 0 })
  const d = Number(credit.limit) > 0 ? Number(credit.used) / Number(credit.limit) : 0;
  const f = 360;
  const data = [
    { name: 'Limit', value: f - f * d },
    { name: 'Used', value: f * d }
  ];
  const COLORS = ['#F2F2F2', '#29ABE2'];

  useEffect(() => {
    ajaxGetDealerCredit({ DealerId: dealerId })
    .then((dealerRes) => {
      const dealer = dealerRes.data;
      if ( dealer ) {
        setCredit({
          used: dealer.credit_used,
          limit: dealer.credit_limit
        });
      }
      else {
        setCredit({ used: 0, limit: 0 });
      }
    })
    .catch(() => {
      setCredit({ used: 0, limit: 0 });
    });
  }, [dealerId]);

  return (
    <StyledCard
      title="Credit Available"
      titleIcon="$"
    >
      <Grid container columnSpacing="22px" sx={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={7}>
          <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '220px' }}>
            <GlobalStyles styles={{ '.recharts-wrapper.available-credit svg': { overflow: 'inherit!important' } }} />
            <ResponsiveContainer width="100%" height={220}>
              <PieChart className="available-credit">
                <Pie
                  data={data}
                  dataKey="value"
                  startAngle={-270}
                  endAngle={270}
                  fill="#29ABE2"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <MoneyFormat suffix=" Used" value={credit.used} />
            <MoneyFormat suffix=" Credit" value={credit.limit} />
          </Box>
        </Grid>
      </Grid>
    </StyledCard>
  );
}
