import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Typography from '@mui/material/Typography';
import StyledCard from '../../components/StyledCard'

export default function ProductionShipmentTodayTotal({ orders }) {
  const totalPendingOrders = useMemo(() => {
    return orders.filter(o => o.shipping_status === 'done' && o.shipping_for_today === 1).length;
  }, [orders]);

  return (
    <StyledCard
      title="Total Shipments for Today"
      titleIcon={TrendingUpIcon}
    >
      <CardContent>
        <Typography
          variant="h1"
          sx={{ textAlign: 'center' }}
        >
          <Box>
            {totalPendingOrders}
          </Box>
        </Typography>
      </CardContent>
    </StyledCard>
  );
}
