import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxOrdersUrl = `${constants.ajax.baseUrl}/sale/orders`;
const ajaxQuotationsUrl = `${constants.ajax.baseUrl}/sale/quotations`;

const ajaxRequestToDeleteOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxOrdersUrl + `/delete/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxСancelRequestToDeleteOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxOrdersUrl + `/delete/cancel/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxRequestToDeleteQuotation = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxQuotationsUrl + `/delete/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxСancelRequestToDeleteQuotation = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxQuotationsUrl + `/delete/cancel/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

export {
  ajaxRequestToDeleteOrder,
  ajaxСancelRequestToDeleteOrder,
  ajaxRequestToDeleteQuotation,
  ajaxСancelRequestToDeleteQuotation
};
