import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ajaxGetDealersAutocomplete } from '../../services/dealerService';
import { ajaxGetDealerStatementOfAccounts } from '../../services/reportsService';
import StyledSearchDate from '../../components/StyledSearchDate';
import useAlert from '../../hooks/useAlert'
import moment from 'moment';

export default function DealerStatementOfAccounts() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [dealers, setDealers] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);
  const errorAlert = useAlert(false, 'error');

  useEffect(() => {
    ajaxGetDealersAutocomplete().then((dealerRes) => {
      const dealerList = dealerRes.data;
        const autocompleteList = [...dealerList.map(d => ({ id: d.id, name: d.name }))]
        setDealers(autocompleteList);
    }).catch(() => {
      setAutocomplete(null);
    });
  }, []);

  useEffect(() => {
    setReadyToDownload(startDate !== null && endDate !== null && !!autocomplete);
  }, [startDate, endDate, autocomplete, dealers]);

  const handleDealerNameSearch = (_, value) => {
    setAutocomplete(value);
  };

  const handleDownload = (format) => () => {
    errorAlert.resetAlert();
    setIsProcessing(true);

    let filename = `statement-of-accounts_${autocomplete.name}_${moment.unix(startDate).format('YYYY-MM-DD')}_${moment.unix(endDate).format('YYYY-MM-DD')}.${format}`;

    // validate filename
    filename = filename.replace(/[\s/\\?%*:|"<>]/g, '');
    
    ajaxGetDealerStatementOfAccounts(
      {
        format: format,
        dealerId: autocomplete.id,
        startDate: startDate,
        endDate: endDate,
      },
      filename
    )
    .then(() => {
      setIsProcessing(false);
    })
    .catch((e) => {
      errorAlert.setAlert(e.message);
      setIsProcessing(false);
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          disablePortal
          fullWidth
          size="small"
          value={autocomplete}
          options={dealers}
          isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
          getOptionLabel={(option) => option.name}
          onChange={handleDealerNameSearch}
          renderInput={(params) => <TextField {...params} label="Dealer Name" />}
        />
      </Grid> 
      <Grid item xs={12} sm={8}>
        <StyledSearchDate
          startDate={startDate}
          endDate={endDate}
          onStartDate={setStartDate}
          onEndDate={setEndDate}
          gridProps={{ columnSpacing: 1 }}
        />
      </Grid> 

      <Grid item xs={12}>
        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 20px 10px 0' }}
          onClick={handleDownload('pdf')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in PDF
        </Button>

        <Button
          size="small"
          variant="contained"
          color="info"
          sx={{ margin: '10px 0' }}
          onClick={handleDownload('csv')}
          disabled={isProcessing || !readyToDownload}
          startIcon={
            isProcessing
              ? <CircularProgress color="info" size={20} thickness={3}/>
              : undefined
          }
        >
          Download in CSV
        </Button>
      </Grid>
    </Grid>
  );
}
