import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxGetOrdersUrl = `${constants.ajax.baseUrl}/shipping/orders`;
const ajaxQuotationsUrl = `${constants.ajax.baseUrl}/shipping/quotations`;

const ajaxGetOrders = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxGetOrdersUrl + `/${params.OrderId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxGetOrdersUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetQuotations = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxQuotationsUrl + `/${params.OrderId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxQuotationsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveOrder = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxGetOrdersUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });


export {
  ajaxGetOrders,
  ajaxGetQuotations,
  ajaxSaveOrder
};
