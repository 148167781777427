import React, {useState, useEffect, useMemo} from 'react';
import inMemoryUser from '../services/inMemoryUser';


export default function useWorkspace(isUser, workspaceName, defaultWorksapce) {
  const consideringWorkspaceName = useMemo(() => {
    if ( isUser ) {
      return `${inMemoryUser.getUsername()}_${workspaceName}`;
    }
    return workspaceName;
  }, [isUser, workspaceName]);

  const loadWs = useMemo(() => {
    let ws = null;
    try {
      ws =  localStorage.getItem(consideringWorkspaceName);
      if ( ws ) {
        ws = JSON.parse(ws);
      }
    }
    catch(e){
    }
    return ws || defaultWorksapce || {};
  }, [consideringWorkspaceName]);

  const [workspace, setWorkspace] = useState(loadWs);

  useEffect(() => {
    let str = JSON.stringify(workspace);
    if ( localStorage.getItem(consideringWorkspaceName) !== str ) {
      localStorage.setItem(consideringWorkspaceName, str);
    }
  }, [consideringWorkspaceName, workspace]);

  return [workspace, setWorkspace];
}