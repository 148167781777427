import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import StyledCard from '../../components/StyledCard'
import {
  ajaxGetOrdersRequestToDelete,
  approveRequestToDeleteOrder,
  rejectRequestToDeleteOrder
} from '../../services/orderService';
import { toDateText } from '../../helpers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ConfirmationButton from '../../components/ConfirmationButton';

export default function RequestToDelete() {
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();
  
  useEffect(() => {
    ajaxGetOrdersRequestToDelete()
      .then((res) => setOrders(res.data))
      .catch(() => {
        setOrders([]);
      });
  }, []);

  const handleApproveRequestToDelete = (id) => 
    approveRequestToDeleteOrder({ OrderId: id })
      .then(() => setOrders(orders.filter(o => o.id !== id)))
      .catch((e) => console.log(e.message));
  
  const handleRejectRequestToDelete = (id) => 
    rejectRequestToDeleteOrder({ OrderId: id })
      .then(() => setOrders(orders.filter(o => o.id !== id)))
      .catch((e) => console.log(e.message));
  
  return (
    <StyledCard
      title="Request to Delete Order"
      titleIcon="$"
    >
      {
        orders.length > 0 ? 
          <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {orders.map((order) => (
            <ListItem
              key={order.id}
              secondaryAction={
                  <React.Fragment>
                    <ConfirmationButton
                      buttonElement={IconButton}
                      buttonProps={{ size: "small", color: "success", children:<DoneIcon /> }}
                      handleDialogClickYes={() => handleApproveRequestToDelete(order.id)}
                      dialogText={ "Are you sure you want to approve to delete this order?" }
                      dialogYesText="Confirm"
                      dialogNoText="Cancel"
                      tooltip="Approve"
                    />
                    <ConfirmationButton
                      buttonElement={IconButton}
                      buttonProps={{ size: "small", color: "error", children:<ClearIcon /> }}
                      handleDialogClickYes={() => handleRejectRequestToDelete(order.id)}
                      dialogText={ "Are you sure you want to reject to delete this order?" }
                      dialogYesText="Confirm"
                      dialogNoText="Cancel"
                      tooltip="Reject"
                    />  
                  </React.Fragment>
              }
            >
              <ListItemButton
                onClick={() => navigate((order.oc_ref && order.invoice ? '/orders/edit/' : '/quotations/edit/') + order.id + '?page=dashboard')}
              >
                <ListItemText primary={`${order.oc_ref ? order.oc_ref : order.quote_ref}`} secondary={`${toDateText(order.date)}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> :
        <Typography align='center' sx={{ p: 2 }}>No requests</Typography>
      }
      
    </StyledCard>
  );
}
