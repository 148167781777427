import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { ListSubheader } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { ajaxLogout } from '../../services/authenticationService';
import inMemoryUser from '../../services/inMemoryUser';
import { AuthContext } from '../../context';

export default function Sidebar(props) {
  const { changeIsUserLogged } = useContext(AuthContext);

  let menuItems = [];

  if (inMemoryUser.isSale()) {
    menuItems = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        href: '/dashboard'
      },
      {
        id: 'products',
        label: 'Products',
        href: '/products'
      },
      {
        id: 'quotations',
        label: 'Quotations',
        href: '/quotations'
      },
      {
        id: 'orders',
        label: 'Orders',
        href: '/orders'
      },
      {
        id: 'dealers',
        label: 'Dealers',
        href: '/dealers'
      },
      {
        id: 'events',
        label: 'Events',
        href: '/events'
      },
      {
        id: 'reports',
        label: 'Reports',
        href: '/reports'
      },
      {
        id: 'logout',
        label: 'Logout',
        href: '/'
      }
    ];
  } else if (inMemoryUser.isShipping()) {
    menuItems = [
      {
        id: 'products',
        label: 'Products',
        href: '/products'
      },
      {
        id: 'quotations',
        label: 'Quotations',
        href: '/quotations'
      },
      {
        id: 'orders',
        label: 'Orders',
        href: '/orders'
      },
      {
        id: 'dealers',
        label: 'Dealers',
        href: '/dealers'
      },
      {
        id: 'events',
        label: 'Events',
        href: '/events'
      },
      {
        id: 'reports',
        label: 'Reports',
        href: '/reports'
      },
      {
        id: 'logout',
        label: 'Logout',
        href: '/'
      }
    ];
  } else if (inMemoryUser.isProduction()) {
    menuItems = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        href: '/dashboard'
      },
      {
        id: 'products',
        label: 'Products',
        href: '/products'
      },
      {
        id: 'quotations',
        label: 'Quotations',
        href: '/quotations'
      },
      {
        id: 'orders',
        label: 'Orders',
        href: '/orders'
      },
      {
        id: 'dealers',
        label: 'Dealers',
        href: '/dealers'
      },
      {
        id: 'events',
        label: 'Events',
        href: '/events'
      },
      {
        id: 'logout',
        label: 'Logout',
        href: '/'
      }
    ];
  } else if (inMemoryUser.isAdmin()) {
    menuItems = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        href: '/dashboard'
      },
      {
        id: 'products',
        label: 'Products',
        href: '/products'
      },
      {
        id: 'quotations',
        label: 'Quotations',
        href: '/quotations'
      },
      {
        id: 'orders',
        label: 'Orders',
        href: '/orders'
      },
      {
        id: 'dealers',
        label: 'Dealers',
        href: '/dealers'
      },
      {
        id: 'users',
        label: 'Users',
        href: '/users'
      },
      {
        id: 'events',
        label: 'Events',
        href: '/events'
      },
      {
        id: 'reports',
        label: 'Reports',
        href: '/reports'
      },
      {
        id: 'logout',
        label: 'Logout',
        href: '/'
      }
    ];
  }

  const navigate = useNavigate();

  const handleLogout = (event) => {
    event.preventDefault();

    props.toggleMenu(false);

    ajaxLogout()
      .then(() => {
        changeIsUserLogged(false);
        navigate('/');
      })
      .catch(console.error);
  };

  const getSubheader = function () {
    return (
      <React.Fragment>
        <ListSubheader id="nav-list-subheader" color="inherit">
          <Typography variant="h5" color="inherit" noWrap sx={{ padding: '10px 5px' }}>
            What would you like to do?
          </Typography>
        </ListSubheader>
        <Divider component="li" sx={{ backgroundColor: '#545454', marginBottom: '0' }} />
      </React.Fragment>
    );
  };

  return (
    <Drawer anchor={'left'} open={props.isOpenMenu} onClose={() => props.toggleMenu(false)}>
      <Toolbar />
      <Box role="presentation">
        <List component="nav" aria-labelledby="nav-list-subheader" subheader={getSubheader()}>
          {menuItems.map((menuItem) => (
            <ListItem key={menuItem.id} disableGutters sx={{ padding: '0 10px' }}>
              {menuItem.id !== 'logout' ? (
                <ListItemButton component={RouterLink} to={menuItem.href} onClick={() => props.toggleMenu(false)}>
                  <ListItemText primaryTypographyProps={{ fontSize: '20px' }} primary={menuItem.label} style={{ lineHeight: 1, margin: 0 }} />
                </ListItemButton>
              ) : (
                <ListItemButton onClick={handleLogout}>
                  <ListItemText primaryTypographyProps={{ fontSize: '20px' }} primary={menuItem.label} style={{ lineHeight: 1, margin: 0 }} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
