import { ajaxCall, ajaxCallBlob, formRequestData } from './ajaxService';
import {getRoleUrl} from '../helpers';

const constants = window.getConfig;

export const ajaxGetProducts = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params && params.ProductId) {
      ajaxCall(ajaxProductsUrl + `/${params.ProductId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      if (params && params.StartDate && params.EndDate) {
        ajaxCall(ajaxProductsUrl + `/withTotalSales/${params.StartDate}/${params.EndDate}`, formRequestData(constants.ajax.methods.get, true))
          .then(res)
          .catch(rej);
      } else {
        ajaxCall(ajaxProductsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
      }
    }
  });

export const ajaxSaveProduct = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    ajaxCall(ajaxProductsUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

export const ajaxDisableProduct = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params.ProductId) {
      ajaxCall(ajaxProductsUrl + `/disable/${params.ProductId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
});

export const ajaxEnableProduct = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params.ProductId) {
      ajaxCall(ajaxProductsUrl + `/enable/${params.ProductId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
});

export const ajaxDeleteProductImage = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params.ProductId) {
      ajaxCall(ajaxProductsUrl + `/deleteImage/${params.ProductId}`, formRequestData(constants.ajax.methods.post, true))
        .then(res)
        .catch(rej);
    }
});

export const ajaxGetProductImageUrl = async (params) => {
  try {
    let ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params.ProductId) {
      ajaxProductsUrl = `${ajaxProductsUrl}/image/${params.ProductId}`;
    }

    const blobRes = await ajaxCallBlob(ajaxProductsUrl, formRequestData(constants.ajax.methods.get, true, false));
    const objectUrl = window.URL.createObjectURL(blobRes.blob);
    return objectUrl;
  } catch (error) {
    console.log(error.message);
  }
};

export const ajaxGetSoldProductsCount = (params) =>
  new Promise((res, rej) => {
    const ajaxProductsUrl = `${constants.ajax.baseUrl}/${getRoleUrl('products')}`;
    if (params && params.ProductId && params.StartDate && params.EndDate) {
      ajaxCall(ajaxProductsUrl + `/soldCount/${params.ProductId}/${params.StartDate}/${params.EndDate}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxProductsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });
