import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { ajaxGetUsers, ajaxDeleteUser } from '../services/adminService';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardSearch from '../components/StyledCardSearch';
import StyledList from '../components/StyledList';
import StyledCardFormContent from '../components/StyledCardFormContent';
import ConfirmationButton from '../components/ConfirmationButton';

export default function UserList() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const handleUserDelete = (id) => {
    ajaxDeleteUser({ UserId: id })
      .then(() => {
        setItems(items.filter((item) => item.id !== id));
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const renderItem = (data, idx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, alignItems: { xs: 'flex-end', sm: 'center' }}}>      
            <IconButton component={RouterLink} to={'/users/edit/' + data.id}>
              <EditOutlinedIcon />
            </IconButton>
            <ConfirmationButton
              buttonElement={IconButton}
              buttonProps={{
                children: <DeleteOutlinedIcon />
              }}
              handleDialogClickYes={() => handleUserDelete(data.id)}
              dialogText="Are you sure you want to delete this user?"
              dialogYesText="Delete"
              dialogNoText="Cancel"
            />
          </Box>
        }
      >
        <ListItemButton component={RouterLink} to={'/users/edit/' + data.id}>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex' }}>
                <Box>{data.first_name} {data.last_name} [{data.user_role}]</Box>
                {
                  !data.active &&
                  <Chip label="Disabled" size="small" color="error" sx={{ ml: '10px' }} />
                }
              </Box>
            }
            secondary={
              <Box sx={{ display: 'flex' }}>
                <Box>{data.username}</Box>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    ajaxGetUsers({})
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  useEffect(() => {
    const results = items.filter((item) => `${(item.username || '')}${(item.company_name || '')}${(item.country || '')}${(item.email || '')}`.toLowerCase().includes(searchTerm));

    setSearchResults(results);
  }, [searchTerm, items]);

  return (
    <Page title="Add / Edit User">
      <StyledCard>
        <StyledCardSearch onSearch={searchChangeHandle} />
        <StyledCardFormContent>
          <StyledList
            addRow
            onAdd={() => navigate('/users/add')}
            dataSource={searchResults}
            renderItem={renderItem}
            sort={{
              sortKey: 'username',
              sortOptions: [
                {
                  key: 'username',
                  label: 'User Name'
                },
                {
                  key: 'active',
                  label: 'Disabled'
                }
              ]
            }}/>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
