import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetDealers, ajaxSaveDealer } from '../services/dealerService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import FileUploader from '../components/FileUploader';
import {countries, isPhone, isFloat, PAYMENT_TERMS} from '../helpers';

export default function Dealer(props) {
  const incomingParams = useParams();

  const [dealer, setDealer] = useState({ country: 'United States' });

  const allFields = useMemo(
    () => {
      return [
      {
        key: 'oem',
        type: 'checkbox',
        label: 'OEM',
      },
      {
        key: 'name',
        type: 'text',
        label: 'Name',
        autoComplete: 'name',
        autoFocus: true,
        gridProps: { xs: 12, sm: 8 }
      },
      {
        key: 'prefix',
        type: 'text',
        label: 'Prefix',
        gridProps: { xs: 12, sm: 4 }
      },
      {
        key: 'country',
        type: 'list',
        label: 'Country',
        dataset: countries.map(c => ({ value: c.name, label: c.name }))
      },
      {
        key: 'blank',
        type: 'blank',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        key: 'same_address',
        type: 'checkbox',
        label: 'Same as Billing Address',
        gridProps: { xs: 12, sm: 6 },
      },
      {
        key: 'billing_address',
        type: 'text',
        label: 'Billing Address',
        gridProps: { xs: 6 },
        multiline: true
      },
      {
        key: !!dealer.same_address ? 'billing_address' : 'shipping_address',
        type: 'text',
        label: 'Shipping Address',
        gridProps: { xs: 6 },
        multiline: true,
        disabled: !!dealer.same_address
      },
      {
        key: 'billing_vat',
        type: 'text',
        label: 'Billing VAT',
        gridProps: { xs: 6 }
      },
      {
        key: !!dealer.same_address ? 'billing_vat' : 'shipping_vat',
        type: 'text',
        label: 'Shipping VAT',
        gridProps: { xs: 6 },
        disabled: !!dealer.same_address
      },
      {
        key: 'billing_email',
        type: 'text',
        label: 'Billing Email',
        gridProps: { xs: 6 }
      },
      {
        key: !!dealer.same_address ? 'billing_email' : 'shipping_email',
        type: 'text',
        label: 'Shipping Email',
        gridProps: { xs: 6 },
        disabled: !!dealer.same_address
      },
      {
        key: 'billing_phone',
        type: 'text',
        label: 'Billing Phone Number',
        gridProps: { xs: 6 }
      },
      {
        key: !!dealer.same_address ? 'billing_phone' : 'shipping_phone',
        type: 'text',
        label: 'Shipping Phone Number',
        gridProps: { xs: 6 },
        disabled: !!dealer.same_address
      },
      {
        key: 'billing_mobile',
        type: 'text',
        label: 'Billing Mobile Number',
        gridProps: { xs: 6 }
      },
      {
        key: !!dealer.same_address ? 'billing_mobile' : 'shipping_mobile',
        type: 'text',
        label: 'Shipping Mobile Number',
        gridProps: { xs: 6 },
        disabled: !!dealer.same_address
      },
      {
        key: 'email',
        type: 'text',
        label: 'Email',
        autoComplete: 'email'
      },
      {
        key: 'email2',
        type: 'text',
        label: 'Email 2',
        autoComplete: 'email'
      },
      {
        key: 'email3',
        type: 'text',
        label: 'Email 3',
        autoComplete: 'email'
      },
      {
        key: 'email4',
        type: 'text',
        label: 'Email 4',
        autoComplete: 'email'
      },
      {
        key: 'phone',
        type: 'text',
        label: 'Phone Number'
      },
      {
        key: 'cellphone',
        type: 'text',
        label: 'Cell Phone Number'
      },
      {
        key: 'website',
        type: 'text',
        label: 'Website',
      },
      {
        key: 'discount',
        type: 'text',
        label: 'Discount %',
        min: 0,
        max: 100,
      },
      {
        key: 'payment_terms',
        type: 'list',
        label: 'Payment Terms',
        dataset: PAYMENT_TERMS.map(p => ({ value: p, label: p }))
      },
      {
        key: 'credit_limit',
        type: 'text',
        label: 'Credit Limit',
      },
      {
        key: 'notes',
        type: 'text',
        label: 'Notes',
        multiline: true
      },
      {
        key: 'order_notes',
        type: 'text',
        label: ' OC/Invoice Notes',
        multiline: true
      },
      {
        key: 'agreement_pdf',
        type: 'file',
        label: 'Agreement PDF'
      }
    ]}
    ,
    [dealer.same_address]
  );

  const requiredFields = useMemo(() => ['name', 'country', 'billing_address', 'email'], []);
  const emailFields = useMemo(() => ['email', 'email2', 'email3', 'email4', 'billing_email', 'shipping_email'], []);
  const phoneFields = useMemo(() => [], []);
  const floatFields = useMemo(() => ['discount', 'credit_limit'], []);

  const [errors, setErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);

  const navigate = useNavigate();

  const paramDealerId = (incomingParams && incomingParams.DealerId ? incomingParams.DealerId : -1);

  useEffect(() => {
    if (!props.add) {
      if (paramDealerId !== -1) {
        const params = { DealerId: paramDealerId };
  
        ajaxGetDealers(params)
          .then((res) => {
            const { data } = res;
            setDealer(data);
          })
          .catch(() => {
            setDealer({});
          });
      }
    }
  }, [paramDealerId, props.add]);

  const validateFields = useCallback(() => {
    const fields = { ...dealer };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (fieldValue && phoneFields.includes(key) && !isPhone(fieldValue)) {
        newErros[key] = 'Please enter a valid phone number';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [dealer, allFields, requiredFields, emailFields, phoneFields, floatFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;

    const params = { ...dealer };
    let url = '/dealers';

    ajaxSaveDealer(params)
      .then(() => navigate(url))
      .catch(() => navigate(url));
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    setDealer({ ...dealer, [key]: value });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;

    setDealer({ ...dealer, [key]: value });
  };

  const getFieldValue = (key) => {
    return dealer && dealer[key] !== undefined && dealer[key] !== null ? dealer[key] : '';
  };

  const getFieldPlaceholder = (key) => key === 'password' && !props.add ? 'leave blank to not change the password' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : '';

  const checkFieldError = (key) => errors && errors[key];

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Add / Edit Dealer">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            {allFields.map((entry, index) => (
              <React.Fragment key={index}>
                {
                  entry['type'] === 'blank' && <Grid item xs={12} {...entry['gridProps']}/>
                }
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      size="small"
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'list' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1} />}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'file' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <FileUploader
                        error={checkFieldError(entry['key'])}
                        accept=".pdf"
                        name={entry['key']}
                        label={entry['label']}
                        icon={<AttachFileIcon />}
                        handleChange={handleUploadFile}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Save Dealer
              </Button>
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/dealers">
                Cancel
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
