import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, LineChart,
  Line, XAxis, YAxis,
  Tooltip as ChartTootip
} from 'recharts';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CircularProgress from '@mui/material/CircularProgress';
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';
import _ from 'underscore'
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
  const style = {
    container: {
      border: '1px solid grey',
      borderRadius: '4px',
      backgroundColor: 'rgba(255,255,255,0.5)',
      fontSize: '12px',
      padding: '8px 16px'
    },
    month: {
      fontWeight: 'bold'
    },
    sale: {
      color: '#0071bd',
      fontSize: '14px',
      fontWeight: 'bold'
    }
  };
  if (active && payload && payload.length) {
    return (
      <div style={style.container}>
        <div style={style.month}>{label}</div>
        <div>
          <span style={style.month}>{'Sales: '}</span>
          <MoneyFormat style={style.sale} value={payload[0].payload.sale} />
        </div>
      </div>
    );
  }

  return null;
};

export default function SummarySales({ orders }) {
  const [monthlySales, setMonthlySales] = useState(false);
  const [localCurrency, setLocalCurrency] = useState('USD');
  const [ytdSale, setYtdSale] = useState({usd: 0, local: 0});
  const [latest6MonthSale, setLatest6MonthSale] = useState({usd: 0, local: 0});
  const [latest12MonthSale, setLatest12MonthSale] = useState({usd: 0, local: 0});
  const [latest30DaysSale, setLatest30DaysSale] = useState({usd: 0, local: 0});
  const [latest7DaysSale, setLatest7DaysSale] = useState({usd: 0, local: 0});
  const [last30DaysSale, setLast30DaysSale] = useState({usd: 0, local: 0});
  const [last7DaysSale, setLast7DaysSale] = useState({usd: 0, local: 0});

  const timeFromToFormat = (from, to) => {
    if ( from.year() !== to.year() ) {
      if ( from.date() !== to.date() || from.date() !== 1 ) {
        return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
      }
      else if ( from.month() !== to.month() ) {
        return `${from.format('MMM YYYY')} - ${to.format('MMM YYYY')}`;
      }
      return `${from.format('YYYY')} - ${to.format('YYYY')}`;
    }
    else if ( from.month() !== to.month() ) {
      if ( from.date() !== to.date() || from.date() !== 1 ) {
        return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
      }
      return `${from.format('MMM YYYY')} - ${to.format('MMM YYYY')}`;
    }
    return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`;
  };

  let now = moment();
  let startLatestYear = moment().utc().startOf('year');
  let startLatest6Month = moment().utc().subtract(5, 'months').startOf('month');
  let startLatest12Month = moment().utc().subtract(11, 'months').startOf('month');
  let startLatest30Day = moment().utc().subtract(30, 'days').startOf('day');
  let startLatest7Day = moment().utc().subtract(7, 'days').startOf('day');
  let startLast30Day = moment(startLatest30Day).utc().subtract(30, 'days').startOf('day');
  let startLast7Day = moment(startLatest7Day).utc().subtract(7, 'days').startOf('day');
  let change30Day = (((latest30DaysSale.usd - last30DaysSale.usd) / last30DaysSale.usd) * 100);
  let change7Day = (((latest7DaysSale.usd - last7DaysSale.usd) / last7DaysSale.usd) * 100);

  useEffect(() => {
    if ( !Array.isArray(orders) ) {
        setMonthlySales(false);
        return;
    }

    let currency = 'USD';
    let saleItems = [...orders];
    let monthlyFormat = 'YYYY-MM';
    let groupedByMonthData = _.groupBy(saleItems, d => {
        return moment.unix(d.date).utc().format(monthlyFormat);
    });

    // last 12 months
    let preparedMonthlyGraphData = [];
    let consideringMoment = moment();
    for ( let i = 0 ; i < 12; i++ ) {
        let monthlyKey = consideringMoment.format(monthlyFormat);
        // create empty data
        let monthlyGraphData = {
        name: consideringMoment.format('MMM YY'),
        sale: 0,
        saleLocal: 0,
        currency: currency
        };

        if ( groupedByMonthData.hasOwnProperty(monthlyKey) ) {
        let rawdata = groupedByMonthData[monthlyKey];
        monthlyGraphData.sale = rawdata.reduce((sum, cur) => sum + cur.value - cur.shipping_cost, 0);
        monthlyGraphData.saleLocal = rawdata.reduce((sum, cur) => sum + cur.value - cur.shipping_cost, 0);
        }

        preparedMonthlyGraphData = [
        monthlyGraphData,
        ...preparedMonthlyGraphData
        ];

        consideringMoment = consideringMoment.subtract(1, 'months');
    }

    setMonthlySales(preparedMonthlyGraphData);

    let vLatestYear = {usd: 0, local: 0};
    let vLatest6Month = {usd: 0, local: 0};
    let vLatest12Month = {usd: 0, local: 0};
    let vLatest30Day = {usd: 0, local: 0};
    let vLatest7Day = {usd: 0, local: 0};
    let vLast30Day = {usd: 0, local: 0};
    let vLast7Day = {usd: 0, local: 0};
    saleItems.forEach(rd => {
        let rdTs = rd.date;
        let rdTotal = rd.value - rd.shipping_cost;
        let rdTotalLocal = rd.value - rd.shipping_cost;
        if ( rdTs >= startLatestYear.unix()    ) { vLatestYear.usd += rdTotal;    vLatestYear.local += rdTotalLocal; }
        if ( rdTs >= startLatest6Month.unix()  ) { vLatest6Month.usd += rdTotal;  vLatest6Month.local += rdTotalLocal; }
        if ( rdTs >= startLatest12Month.unix()   ) { vLatest12Month.usd  += rdTotal;  vLatest12Month.local  += rdTotalLocal; }
        if ( rdTs >= startLatest30Day.unix()   ) { vLatest30Day.usd  += rdTotal;  vLatest30Day.local  += rdTotalLocal; }
        if ( rdTs >= startLatest7Day.unix()    ) { vLatest7Day.usd  += rdTotal;   vLatest7Day.local  += rdTotalLocal; }
        if ( rdTs >= startLast30Day.unix() && rdTs < startLatest30Day.unix() ) { vLast30Day.usd  += rdTotal; vLast30Day.local  += rdTotalLocal; }
        if ( rdTs >= startLast7Day.unix()  && rdTs < startLatest7Day.unix()  ) { vLast7Day.usd  +=  rdTotal; vLast7Day.local  += rdTotalLocal; }
    });

    setYtdSale(vLatestYear);
    setLatest6MonthSale(vLatest6Month);
    setLatest12MonthSale(vLatest12Month);
    setLatest30DaysSale(vLatest30Day);
    setLatest7DaysSale(vLatest7Day);
    setLast30DaysSale(vLast30Day);
    setLast7DaysSale(vLast7Day);
  }, []);

  const secondarySx = { color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'bold' };
  return (
    <StyledCard
      title="Sales Summary"
      titleIcon={TrendingUpIcon}
    >
      <CardContent>
        <Grid
          container
          rowSpacing="15px"
          columnSpacing="15px"
        >
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12}>
              <Tooltip title={timeFromToFormat(startLatestYear, now)}>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box>
                    <Typography variant="h6" sx={secondarySx}>
                      TOTAL REVENUE
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'baseline'}}>
                      <Typography variant="h4">
                        <MoneyFormat decimalScale={0} value={ytdSale.usd} />
                      </Typography>
                      &nbsp;
                      <Typography variant="caption" sx={secondarySx}>
                        YTD
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
            </Grid>
            <Grid container item xs={12} alignItems="flex-end">
              <Grid item xs={6}>
                <Tooltip title={timeFromToFormat(startLatest6Month, now)}>
                  <Box>
                    <Box>
                      <Typography variant="h6">
                        <MoneyFormat decimalScale={0} value={latest6MonthSale.usd} />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        Latest 6 Months
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={timeFromToFormat(startLatest12Month, now)}>
                  <Box>
                    <Box>
                      <Typography variant="h6">
                        <MoneyFormat decimalScale={0} value={latest12MonthSale.usd} />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        Latest 12 Months
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid container item xs={12} alignItems="flex-end">
              <Grid item xs={6}>
                <Tooltip
                  title={
                    <Box>
                      <Box>
                        {timeFromToFormat(startLast30Day, moment(startLatest30Day).subtract(1, 'seconds'))} = 
                        <MoneyFormat decimalScale={0} value={last30DaysSale.usd} />
                      </Box>
                      <Box>
                        {timeFromToFormat(startLatest30Day, now)} = 
                        <MoneyFormat decimalScale={0} value={latest30DaysSale.usd} />
                      </Box>
                    </Box>
                  }
                >
                  <Box>
                    <Box>
                      <Typography variant="h5" sx={{ color: change30Day >= 0 ? 'green' : '#d32f2f', display: 'flex', alignItems: 'center' }}>
                        {
                          change30Day > 0 &&
                          <TrendingUpIcon sx={{ mr: '5px' }}/>
                        }
                        {
                          change30Day < 0 &&
                          <TrendingDownIcon sx={{ mr: '5px' }}/>
                        }
                        {isNaN(change30Day) ? 'N/A' : change30Day === Infinity ? 100 : Math.round(change30Day)}%
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        30 Days Changed
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={
                    <Box>
                      <Box>
                        {timeFromToFormat(startLast7Day, moment(startLatest7Day).subtract(1, 'seconds'))} = 
                        <MoneyFormat decimalScale={0} value={last7DaysSale.usd} />
                      </Box>
                      <Box>
                        {timeFromToFormat(startLatest7Day, now)} = 
                        <MoneyFormat decimalScale={0} value={latest7DaysSale.usd} />
                      </Box>
                    </Box>
                  }
                >
                  <Box>
                    <Box>
                      <Typography variant="h5" sx={{ color: change7Day >= 0 ? 'green' : '#d32f2f', display: 'flex', alignItems: 'center' }}>
                        {
                          change7Day > 0 &&
                          <TrendingUpIcon sx={{ mr: '5px' }}/>
                        }
                        {
                          change7Day < 0 &&
                          <TrendingDownIcon sx={{ mr: '5px' }}/>
                        }
                        {isNaN(change7Day) ? 'N/A' : change7Day === Infinity ? 100 : Math.round(change7Day)}%
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" sx={secondarySx}>
                        7 Days Changed
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Box sx={{ ml: '-5px', height: '120px' }}>
                {
                  monthlySales ?
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      layout="horizontal"
                      data={monthlySales}
                      margin={{
                        top: 10,
                        left: 5,
                        bottom: 10,
                        right: 20
                      }}
                    >
                      <XAxis hide dataKey="name" type="category" />
                      <YAxis hide type="number" />
                      <ChartTootip content={<CustomTooltip />} />
                      <Line type="monotone" dataKey={'sale'} strokeWidth={3} stroke="#0071bd" dot={false} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                  :
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                    <CircularProgress color="info" />
                  </Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}
