import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import { ajaxGetDealers } from '../services/dealerService';
import { toDateText, downloadFile, viewFile } from '../helpers';
import Page from '../components/Page';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import EnhancedTable from '../components/EnhancedTable'
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MoneyFormat } from '../components/MoneyFormat';
import inMemoryUser from '../services/inMemoryUser';

const downloadAgreementFile = (id, fileType, fileName, fileExt) =>
  <div style={{ textAlign: 'center' }}>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        viewFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <PictureAsPdfIcon fontSize="small"/>
    </IconButton>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        downloadFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <FileDownloadIcon fontSize="small"/>
    </IconButton>
  </div>

export default function DealerList() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { id: 'name', label: 'Name', minWidth: 100, sortable: true },
    { id: 'prefix', label: 'Prefix', minWidth: 80, sortable: true },
    { 
      id: 'billing_address',
      label: 'Billing address',
      minWidth: 170,
      sortable: true,
      format: (v)=><div style={{whiteSpace: 'pre-line'}}>{v}</div>
    },
    { 
      id: 'shipping_address',
      label: 'Shipping address',
      minWidth: 170,
      sortable: true,
      format: (_, row)=><div style={{whiteSpace: 'pre-line'}}>{!!row.same_address ? row.billing_address : row.shipping_address}</div>
    },
    { id: 'country', label: 'Country', minWidth: 100, sortable: true },
    { id: 'email', label: 'E-mail', minWidth: 100, sortable: true },
    { id: 'phone', label: 'Contact Phone', minWidth: 100, sortable: true },
    { id: 'cellphone', label: 'Cell Phone', minWidth: 100, sortable: true },
    { id: 'website', label: 'Website', minWidth: 100, sortable: true },
    {
      id: 'oem',
      label: 'OEM',
      minWidth: 50,
      format: (value) => value ? <div style={{ textAlign: 'center' }}><Checkbox size="small" sx={{ padding: 0 }} disabled {...(parseInt(value) > 0 ? { checked: true } : {})} /></div> : '',
    },
    {
      id: 'discount',
      label: 'Discount',
      minWidth: 80,
      align: 'right',
      sortable: true,
      format: (v) => v ? v+'%':'',
    },
    { id: 'payment_terms', label: 'Payment Terms', minWidth: 170, sortable: true },
    {
      id: 'agreement_pdf',
      label: 'Agreement pdf',
      minWidth: 100,
      format: (v, row) => v && downloadAgreementFile(row.id, 'dealer/agreement_pdf', row.argreement_pdf, 'pdf')
    },
    {
      id: 'credit_limit',
      label: 'Credit Limit',
      minWidth: 60,
      sortable: true,
      format: (v) => v ? <MoneyFormat currency="$" value={v}/> : ''
    },
    {
      id: 'notes',
      label: 'Notes',
      minWidth: 150,
      sortable: true,
      format: (v) => <div style={{ whiteSpace: 'pre-line' }}>{v}</div>
    },
    {
      id: 'order_notes',
      label: 'OC/Invoice Notes',
      minWidth: 150,
      sortable: true,
      format: (v) => <div style={{ whiteSpace: 'pre-line' }}>{v}</div>
    }
  ];

  useEffect(() => {
    ajaxGetDealers({})
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, []);

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const results = items.filter((item) => `${(item.name || '')}${(item.country || '')}${(item.address || '')}${(item.email || '')}${(item.phone || '')}${(item.website || '')}${(item.notes || '')}`.toLowerCase().includes(searchTerm));
    setSearchResults(results);
  }, [searchTerm, items]);

  return (
    <Page title="Dealers" maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Grid xs={12} item>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Search"
            placeholder="Search keyword"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={searchChangeHandle}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
      </Box>
      <Box>
        <Grid xs={12} item>
          {
            inMemoryUser.isAdmin() || inMemoryUser.isSale() ?
            <Button
              size="small"
              variant="contained"
              color="info"
              sx={{ margin: '10px 0' }}
              startIcon={<AddIcon />}
              onClick={() => navigate('/dealers/add')}
            >
              New dealer
            </Button>
            :
            <div style={{ height: '15px' }}/>
          }
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
          <EnhancedTable border fullHeight stickyColumn fontSize="12px" columns={columns} items={searchResults} onRowClick={(id)=>navigate('/dealers/edit/'+id)}/>
        </Box>
      </Box>
    </Page>
  );
}
