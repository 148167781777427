import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxOrdersUrl = `${constants.ajax.baseUrl}/orders`;
const ajaxAdminOrdersUrl = `${constants.ajax.baseUrl}/admin/orders`;
const ajaxQuotationsUrl = `${constants.ajax.baseUrl}/quotations`;
const ajaxAdminQuotationsUrl = `${constants.ajax.baseUrl}/admin/quotations`;

const ajaxGetOrders = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxOrdersUrl + `/${params.OrderId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxOrdersUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxGetOrdersRequestToDelete = () =>
  new Promise((res, rej) =>
    ajaxCall(ajaxOrdersUrl + `/request-to-delete`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej)
  );

const ajaxGetOrdersByTime = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${ajaxOrdersUrl}/time/${params.start}/${params.end}`, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  });

const ajaxGetOrdersWithoutCancelByTime = (params) => {
    return new Promise((res, rej) => {
      ajaxCall(`${ajaxOrdersUrl}/time/${params.start}/${params.end}`, formRequestData(constants.ajax.methods.get, true))
      .then((orderRes) => {
        if ( orderRes && Array.isArray(orderRes.data) ) {
          orderRes.data = orderRes.data.filter(order => order.shipping_status !== 'cancel');
        }
        return res(orderRes);
      })
      .catch(rej);
    });
}

const ajaxSaveOrder = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxOrdersUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminOrdersUrl + `/delete/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const approveRequestToDeleteOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminOrdersUrl + `/delete/approve/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const rejectRequestToDeleteOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminOrdersUrl + `/delete/reject/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

// Quotations

const ajaxGetQuotations = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxQuotationsUrl + `/${params.OrderId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxQuotationsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveQuotation = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxQuotationsUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteQuotation = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminQuotationsUrl + `/delete/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const approveRequestToDeleteQuotation = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminQuotationsUrl + `/delete/approve/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const rejectRequestToDeleteQuotation = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxAdminQuotationsUrl + `/delete/reject/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxTurnQuotationToOrder = (params) =>
  new Promise((res, rej) => {
    if (params && params.OrderId) {
      ajaxCall(ajaxQuotationsUrl + `/turn-to-order/${params.OrderId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

export {
  ajaxGetOrders,
  ajaxSaveOrder,
  ajaxDeleteOrder,
  ajaxGetOrdersByTime,
  ajaxGetOrdersWithoutCancelByTime,
  ajaxGetOrdersRequestToDelete,
  approveRequestToDeleteOrder,
  rejectRequestToDeleteOrder,
  ajaxGetQuotations,
  ajaxSaveQuotation,
  ajaxDeleteQuotation,
  approveRequestToDeleteQuotation,
  rejectRequestToDeleteQuotation,
  ajaxTurnQuotationToOrder
};
