import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Header from './components/Header';
import Sidebar from './components/Sidebar/Sidebar';
import LoadingSpinner from './components/LoadingSpinner';
import Login from './pages/Login';
import { ajaxRefreshToken } from './services/authenticationService';
import inMemoryJwtService from './services/inMemoryJwtService';
import inMemoryUser from './services/inMemoryUser';
import { AuthContext } from './context';
import { adminRoutes, saleRoutes, shippingRoutes, productionRoutes} from './router/routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const theme = createTheme({
  typography: {
    body1: {
      fontSize: '16px'
    },
    button: {
      fontSize: '18px'
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#333333'
    },
    custom: {
      main: '#1976d2'
    },
    error: {
      main: '#D9534F'
    },
    warning: {
      main: '#F59C1A'
    },
    success: {
      main: '#1AB394'
    }
  },
  mixins: {
    toolbar: {
      height: '52px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        containedPrimary: {
          backgroundColor: '#0071bd'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: '#0071bd'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '18px'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            width: '380px',
            flexShrink: 0
          }
        },
        paper: {
          backgroundColor: '#333333',
          color: 'white'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          color: '#000',
          backgroundColor: '#fff'
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: '#333333'
        }
      }
    }
  }
});

const Wrapper = styled('div')({
  height: 'calc(100vh - ' + theme.mixins.toolbar.height + ')',
  display: 'flex',
  flexDirection: 'row'
});

const AlertToken = styled(Alert)({
  alignSelf: 'flex-end',
  width: '100%'
});

export default function App() {
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);

  const changeIsUserLogged = (isLogged, access_token) => {
    if (isLogged === false) {
      inMemoryJwtService.deleteToken();
    } else {
      inMemoryJwtService.setToken(access_token);
    }

    setIsUserLogged(isLogged);
  };

  const toggleMenu = (open) => {
    if ( typeof open === 'undefined' ) {
      open = !isOpenMenu;
    }
    setOpenMenu(open)
  };

  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    ajaxRefreshToken(true)
      .then((response) => {
        const { access_token } = response;
        if (access_token) {
          changeIsUserLogged(true, access_token);
        }

        setIsAppReady({ status: 'ok' });
      })
      .catch((e) => {
        setIsAppReady({ status: 'error', message: e.message });
      });

    const setLogoutFunc = (event) => {
      setIsUserLogged(false);
    };
    window.addEventListener('force_logout', setLogoutFunc);
    return () => {
      window.removeEventListener('force_logout', setLogoutFunc);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isUserLogged,
        changeIsUserLogged,
        inMemoryUser
      }}
    >
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            {!isAppReady && <LoadingSpinner />}

            {isAppReady && (
              <React.Fragment>
                <Header toggleMenu={toggleMenu} />
                <Wrapper>
                  {isAppReady['status'] === 'error' && <AlertToken severity="error">{isAppReady['message']}</AlertToken>}

                  {isUserLogged && (
                    <React.Fragment>
                      <Routes>
                        {
                          inMemoryUser.isAdmin() ? adminRoutes.map((route) => <Route key={route.path} exact={route.exact} path={route.path} element={<route.element key={route.path} {...route.elementProps} />} />) :
                          inMemoryUser.isSale() ? saleRoutes.map((route) => <Route key={route.path} exact={route.exact} path={route.path} element={<route.element key={route.path} {...route.elementProps} />} />) :
                          inMemoryUser.isShipping() ? shippingRoutes.map((route) => <Route key={route.path} exact={route.exact} path={route.path} element={<route.element key={route.path} {...route.elementProps} />} />) :
                          inMemoryUser.isProduction() ? productionRoutes.map((route) => <Route key={route.path} exact={route.exact} path={route.path} element={<route.element key={route.path} {...route.elementProps} />} />) : []
                        }
                        {
                          ( inMemoryUser.isAdmin() || inMemoryUser.isSale() || inMemoryUser.isProduction() ) ?
                          <Route exact path="*" element={<Navigate to="/dashboard" />} />
                          :
                          <Route exact path="*" element={<Navigate to="/orders" />} />
                        }
                      </Routes>
                      <Sidebar isOpenMenu={isOpenMenu} toggleMenu={toggleMenu} />
                    </React.Fragment>
                  )}

                  {isAppReady && isAppReady['status'] === 'ok' && !isUserLogged && <Login />}
                </Wrapper>
              </React.Fragment>
            )}
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}
