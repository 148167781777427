import React, {useMemo, useState, useEffect} from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import * as COLORS from '@mui/material/colors';
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';
import moment from 'moment';

const srcColors = Object.keys(COLORS).filter(k => COLORS[k].hasOwnProperty(100))
const colors = [
  ...srcColors.map(k => COLORS[k][100]),
  ...srcColors.map(k => COLORS[k][200]),
  ...srcColors.map(k => COLORS[k][300]),
  ...srcColors.map(k => COLORS[k][400]),
  ...srcColors.map(k => COLORS[k][500]),
  ...srcColors.map(k => COLORS[k][600]),
  ...srcColors.map(k => COLORS[k][700]),
  ...srcColors.map(k => COLORS[k][800]),
  ...srcColors.map(k => COLORS[k][900])
].filter(c => !!c);

export default function DealerSales({ orders, dealers }) {
  const startOfCurrentMonth = moment().startOf('month');
  const [activeIndex, setActiveIndex] = useState(false);
  const [datetime, setDatetime] = useState(startOfCurrentMonth.unix());

  const data = useMemo(() => {
    let isRange = false;
    let consideringDatetime = datetime;
    if ( datetime < 0 ) {
      consideringDatetime = moment().subtract((datetime * -1) - 1, 'months').startOf('month').unix();
      isRange = true;
    }
    const startMoment = moment.unix(consideringDatetime);
    const filterMonthOrders = orders.filter(o => isRange ? moment.unix(o.date).isAfter(startMoment) : moment.unix(o.date).isSame(startMoment, 'month'));
    const dealersChartData = dealers.map((d, idx) => {
      return {
        ...d,
        total: filterMonthOrders.filter(o => o.dealer_id === d.id).reduce((sum, cur) => (sum + cur.value - cur.shipping_cost), 0)
      };
    }).sort((a, b) => b.total - a.total);
    const top10ChartData = dealersChartData.slice(0, 10);
    const othersChartData = dealersChartData.slice(10).reduce((sum, cur) => (sum.map(s => ({...s, total: s.total + cur.total}))), [{ name: 'Others', total: 0 }]);
    const total = dealersChartData.reduce((sum, cur) => (sum + cur.total), 0);
    let chartData = [
      ...top10ChartData,
      ...othersChartData
    ].map((c, idx) => ({...c, color: colors[idx % colors.length]}));
    if ( total === 0 ) {
      chartData = [{ name: 'No Sales', total: 1, noSale: true, color: COLORS['grey'][100]}];
    }
    return {
      chartData,
      total
    };
  }, [orders, dealers, datetime]);

  useEffect(() => {
    setActiveIndex(data.chartData.length > 1 ? 0 : false);
  }, [data]);

  const onPieEnter = (e, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = (e, index) => {
    setActiveIndex(false);
  };

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius) * cos;
    const sy = cy + (outerRadius) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {/*
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <foreignObject
            style={{
              fontSize: '12px',
              width: '70px',
              height: '70px',
              textAlign: 'center',
              color: '#333',
              overflow: 'visible',
              transform: textAnchor === 'end' ? 'translate(-70px, -35px)' : 'translateY(-35px)'
            }}
            x={ex + (cos >= 0 ? 1 : -1) * 2}
            y={ey}
          >
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div>{payload.name}</div>
              <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {
                  payload.noSale ?
                  <MoneyFormat prefix="$" value={0} />
                  :
                  <MoneyFormat prefix="$" value={payload.total} />
                }
              </div>
            </div>
          </foreignObject>
        */}
      </g>
    );
  };

  const activeData = (data.chartData.length > activeIndex ? data.chartData[activeIndex] : false);

  return (
    <StyledCard
      title="Top 10 dealers"
      titleIcon="$"
    >
      <Grid container columnSpacing="22px" sx={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={7}>
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', minHeight: '220px' }}>
            <GlobalStyles styles={{ '.recharts-wrapper.dealer-sale svg': { overflow: 'inherit!important' } }} />
            <ResponsiveContainer width="100%" height={220}>
              <PieChart className="dealer-sale">
                <Pie
                  data={data.chartData}
                  dataKey="total"
                  startAngle={90}
                  endAngle={450}
                  outerRadius="80%"
                  fill="#29ABE2"
                  activeShape={renderActiveShape}
                  onMouseMove={onPieEnter}
                  onMouseLeave={onPieLeave}
                  activeIndex={activeIndex}
                >
                  {data.chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            {
              activeData &&
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.7)',
                  pointerEvents: 'none'
                }}
              >
                <Box>{activeData.name}</Box>
                <Box>
                  {
                    activeData.noSale ?
                    <MoneyFormat prefix="$" value={0} />
                    :
                    <MoneyFormat prefix="$" value={activeData.total} />
                  }
                </Box>
              </Box>
            }
          </Box>
        </Grid>
        <Grid container item xs={6} sm={5} rowSpacing="48px"  sx={{ pr: '22px', mt: '-90px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <Select
                size="small"
                value={datetime}
                onChange={(e) => setDatetime(e.target.value)}
              >
                <MenuItem value={-3}>Latest 3 Months</MenuItem>
                <MenuItem value={-6}>Latest 6 Months</MenuItem>
                <MenuItem value={-12}>Latest 12 Months</MenuItem>
                <Divider />
                {
                  (new Array(12)).fill(0).map((e, idx) => {
                    let d = moment(startOfCurrentMonth).subtract(idx, 'months').startOf('month');
                    return <MenuItem value={d.unix()}>{d.format('MMMM YYYY')}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              <MoneyFormat prefix="Total: $ " value={data.total} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
}
