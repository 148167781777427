import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import inMemoryUser from '../../services/inMemoryUser';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import EnhancedTable from '../../components/EnhancedTable'
import Page from '../../components/Page';
import { MoneyFormat } from '../../components/MoneyFormat';
import StyledSearchDate from '../../components/StyledSearchDate';
import { isFloat, downloadFile, viewFile } from '../../helpers';
import { ajaxGetProducts } from '../../services/productService';

const downloadProductImgFile = (id, fileType, fileName, fileExt) =>
  <div style={{ textAlign: 'center' }}>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        viewFile({ params: { id, fileType }, fileName, fileExt })
      }}
    >
      <PhotoLibraryIcon fontSize="small"/>
    </IconButton>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        downloadFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <FileDownloadIcon fontSize="small"/>
    </IconButton>
  </div>

export default function ProductList() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [productStatus, setProductStatus] = useState('all');
  const [startDate, setStartDate] = useState(moment().add(-1, 'M').startOf('day').unix());
  const [endDate, setEndDate] = useState(moment().endOf('day').unix());
  const navigate = useNavigate();

  const columns = [
    {
      id: 'product_code',
      label: 'Product Code',
      minWidth: 100,
      sortable: true
    },
    {
      id: 'hs_code',
      label: 'HS Code',
      sortable: true
    },
    {
      id: 'desc',
      label: 'Description',
      sortable: true
    },
    {
      id: 'msrp',
      label: 'MSRP',
      sortable: true,
      format: v => (isFloat(v) ? <MoneyFormat currency="$" value={v}/> : '')
    },
    {
      id: 'discount',
      label: 'Discount',
      sortable: true,
      format: v => (isFloat(v) ? `${v}%` : '')
    },
    {
      id: 'quantity',
      label: 'Total sales',
      sortable: true,
      summary: (item, column) => item[column.id] || 0
    },
    {
      id: 'is_shipping_cost',
      label: 'Shipping',
      sortable: true,
      format: (value) =>
        !!value &&
        <div style={{ textAlign: 'center' }}>
          <Chip sx={{color: '#ffffff', height: '18px', fontSize: '12px'}} label={'Yes'} color={'success'} size="small" />
        </div>
    },   
    {
      id: 'active',
      label: 'Status',
      sortable: true,
      format: (value) =>
        <div style={{ textAlign: 'center' }}>
          <Chip
            sx={{color: '#ffffff', height: '18px', fontSize: '12px'}}
            label={value === 1 ? 'Enabled' : 'Disabled'}
            color={!!value ? 'success' : 'error'}
            size="small"
          />
        </div>
    },
    {
      id: 'product_image',
      label: 'Image',
      format: (v, row) => v && downloadProductImgFile(row.id, 'product/product_image', row.product_image, 'png')
    }
  ];

  useEffect(() => {
    ajaxGetProducts({ StartDate: startDate, EndDate: endDate })
      .then((res) => {
        const { data } = res;
        setItems(data);
      })
      .catch(() => {
        setItems([]);
      });
  }, [startDate, endDate]);

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const productStatusRule = {
      'all': [0, 1],
      'active': [1],
      'inactive': [0]
    };
    const results = items
                    .filter((item) => `${(item.product_code || '')}${(item.desc || '')}${(item.hs_code || '')}`.toLowerCase().includes(searchTerm))
                    .filter((item) => productStatusRule[productStatus].indexOf(item.active) !== -1);
    setSearchResults(results);
  }, [productStatus, searchTerm, items]);

  return (
    <Page title="Products" maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <TextField
              fullWidth
              size="small"
              type="text"
              label="Search"
              placeholder="Search keyword"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={searchChangeHandle}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid xs={6} item>
            <StyledSearchDate
              startDate={startDate}
              endDate={endDate}
              onStartDate={setStartDate}
              onEndDate={setEndDate}
              gridProps={{ columnSpacing: 1 }}
            />
          </Grid>
          <Grid xs={12} item>
            <FormControl sx={{ mt: '10px' }}>
              <FormLabel>Product Status</FormLabel>
              <RadioGroup
                row
                value={productStatus}
                onChange={(e) => setProductStatus(e.target.value)}
              >
                <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                <FormControlLabel value="active" control={<Radio size="small"/>} label="Active" />
                <FormControlLabel value="inactive" control={<Radio size="small"/>} label="Inactive" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      { !inMemoryUser.isShipping() &&
        <Box>
          <Grid xs={12} item>
            <Button
              size="small"
              variant="contained"
              color="info"
              sx={{ margin: '10px 0' }}
              startIcon={<AddIcon />}
              onClick={() => navigate('/products/add')}
            >
              New Product
            </Button>
          </Grid>
        </Box>
      }
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
          <EnhancedTable
            border
            fullHeight
            stickyColumn
            fontSize="12px"
            ordeyBy="product_code"
            columns={columns}
            items={searchResults}
            onRowClick={(id)=>navigate('/products/edit/'+id)}
          />
        </Box>
      </Box>
    </Page>
  );
}
