import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import UtcDatePicker from '../components/UtcDatePicker';
import { useParams } from 'react-router-dom';
import { ajaxGetOrders, ajaxSaveOrder } from '../services/shippingOrderService';
import { ajaxGetDealersAutocomplete } from '../services/dealerService';
import validator from 'validator';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import FileUploader from '../components/FileUploader';
import moment from 'moment';
import { toDate, isFloat, noty } from '../helpers';


export default function ShippingOrder(props) {
  const incomingParams = useParams();

  const [order, setOrder] = useState({
    shipping_status: 'wait',
    date: moment().hour(0).minute(0).second(0).millisecond(0).unix() + (moment().utcOffset() * 60)
  });
  const [dealers, setDealers] = useState([]);

  const allFields = useMemo(
    () => [
      {
        key: 'dealer',
        type: 'autocomplete',
        label: 'Company',
        dataset: dealers.map(d=>({value:d.id, label:d.name})),
        gridProps: { xs: 12, sm: 6 }
      },
      {
        key: 'date',
        type: 'date',
        label: 'Order Date',
        gridProps: { xs: 12, sm: 6 }
      },
      {
        key: 'quote_ref',
        type: 'text',
        label: 'Quotation Ref.',
        gridProps: { xs: 12, sm: 4 },
        disabled: true
      },
      {
        key: 'oc_ref',
        type: 'text',
        label: 'OC Ref.',
        gridProps: { xs: 12, sm: 4 },
        // autoFocus: true
        disabled: true
      },
      {
        key: 'invoice',
        type: 'text',
        label: 'Invoice No.',
        gridProps: { xs: 12, sm: 4 },
        disabled: true
      },
      {
        key: 'note',
        type: 'text',
        label: 'Note',
        divider: true,
        multiline: true
      },
      {
        key: 'shipping_for_today',
        type: 'checkbox',
        label: 'Shipments for Today',
        disabled: order.shipping_status === 'done',
        gridProps: { xs: 6 }
      },
      {
        key: 'distributor_shipments',
        type: 'checkbox',
        label: 'Distributor Shipments',
        disabled: order.shipping_status === 'done',
        gridProps: { xs: 6 }
      },
      {
        key: 'shipping_date_expected',
        type: 'date',
        label: 'Expected Shipping Date',
        gridProps: { xs: 6 }
      },
      {
        key: 'shipping_date',
        type: 'date',
        label: 'Shipping Date',
        gridProps: { xs: 6 }
      },
      {
        key: 'shipping_status',
        type: 'list',
        label: 'Shipping',
        dataset: [{value:'ship', label:'Ship'}, {value:'done', label:'Done'}],
        disabled: !!order.shipping_awb
      },
      {
        key: 'shipping_cost',
        type: 'text',
        label: 'Shipping Cost',
        gridProps: { xs: 12, sm: 4 }
      },
      {
        key: 'actual_shipping_cost',
        type: 'text',
        label: 'Actual Shipping Cost',
        gridProps: { xs: 12, sm: 4 }
      },
      {
        key: 'shipping_weight',
        type: 'text',
        label: 'Shipping Weight',
        gridProps: { xs: 12, sm: 4 }
      },
      {
        key: 'shipping_awb',
        type: 'text',
        label: 'AWB',
        gridProps: { xs: 12, sm: 8 }
      },
      {
        key: 'shipping_invoice_pdf',
        type: 'file',
        label: 'Shipping Invoice',
        gridProps: { xs: 6, sm: 2 }
      },
      {
        key: 'shipping_packing_list_pdf',
        type: 'file',
        label: 'Packing List',
        gridProps: { xs: 6, sm: 2 }
      }
    ],
    [ dealers, order.shipping_status, order.shipping_awb ]
  );
  

  const requiredFields = useMemo(() => ['dealer', 'date', 'oc_ref', 'oc_pdf', ...(order.shipping_status === 'done' ? ['shipping_awb'] : [])], [order.shipping_status]);

  const emailFields = useMemo(() => ['email'], []);

  const floatFields = useMemo(() => ['shipping_cost', 'actual_shipping_cost'], []);

  const [errors, setErrors] = useState({});
  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const paramOrderId = (incomingParams && incomingParams.OrderId ? incomingParams.OrderId : -1);

  useEffect(() => {
    ajaxGetDealersAutocomplete({})
      .then((res) => {
        const { data } = res;
        setDealers(data);
      })
      .catch(() => {
        setDealers([]);
      });
  }, []);

  useEffect(() => {
    if (!props.add) {
      if (paramOrderId !== -1) {
        const params = {OrderId: paramOrderId};
  
        ajaxGetOrders(params)
          .then((res) => {
            const { data } = res;
            setOrder(data);
          })
          .catch(() => {
            setOrder({});
          });
      }
    }
  }, [paramOrderId, props.add]);

  const validateFields = useCallback(() => {
    const fields = { ...order };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [order, allFields, requiredFields, emailFields, floatFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  useEffect(() => {
    if ( duplicateErrors.oc_ref ) {
      setDuplicateErrors({
        ...duplicateErrors,
        oc_ref : null
      });
    }
  }, [order.oc_ref]);

  useEffect(() => {
    if ( duplicateErrors.invoice ) {
      setDuplicateErrors({
        ...duplicateErrors,
        invoice : null
      });
    }
  }, [order.invoice]);

  const handleSave = () => {
    setSubmitOnce(true);
    setIsSaving(true);
    if (!validateFields()) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    const params = { ...order };
    ajaxSaveOrder(params)
    .then((res) => {
      if (res.error) {
        noty(res.error, 'error');
      } else {
        noty('Save successfully', 'success');
      }
      setOrder(res.data);
      setIsSaving(false)
    })
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        setIsSaving(false)
        console.log(e.message)
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = typeof event === 'string' ? event : event.target.name;
    
    if ( key === 'dealer' ) {
      setOrder({ ...order, [key]: value ? value.value : null });
    } else if ( key === 'shipping_status' && value === 'done' ) {
      setOrder({
        ...order,
        shipping_status: value,
        shipping_for_today: 0
      });
    }
    else if ( key === 'shipping_awb' && !!value ) {
      setOrder({
        ...order,
        shipping_awb: value,
        shipping_status: 'done',
        shipping_for_today: 0
      });
    }
    else {
      setOrder({ ...order, [key]: value });
    }
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    let filename = value.name.replace('.pdf', '');
    let newOrder = {
      ...order,
      [key]: value
    };
    if ( key === 'oc_pdf' ) {
      newOrder = {
        ...newOrder,
        oc_ref: filename
      };
    }
    else if ( key === 'invoice_pdf' ) {
      newOrder = {
        ...newOrder,
        invoice: filename
      };
    }
    setOrder(newOrder);
  };

  const getFieldValue = (key) => order && order[key] !== undefined && order[key] !== null ? order[key] : '';

  const getFieldPlaceholder = (key) => key === 'password' && !props.add ? 'leave blank to not change the password' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : (duplicateErrors && duplicateErrors[key] ? duplicateErrors[key] : '');

  const checkFieldError = (key) => (errors && errors[key]) || (duplicateErrors && duplicateErrors[key]);

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Shipping Add / Edit Order">
      <StyledCard>
        <StyledCardFormContent>
        {
            !props.add &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '25px', mt: '-20px' }}>
              <Link to={`/events/order/${order.id}`}>
                View History
              </Link>
            </Box>
          }
          <StyledMainGrid columnSpacing alignItems="center" >
            {allFields.map((entry, index) => (
              <React.Fragment key={entry['key']}>
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'date') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <UtcDatePicker
                      label={entry['label']}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: checkFieldError(entry['key']),
                          helperText: getFieldErrorText(entry['key'])
                        },
                      }}
                      value={getFieldValue(entry['key']) ? moment(toDate(getFieldValue(entry['key']))).unix() : null}
                      onChange={(value) => handleFieldChange(entry['key'], value)}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'autocomplete' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <Autocomplete
                        sx={{ width: '100%' }}
                        size="small" 
                        value={(entry.dataset || []).find(o=>o.value === getFieldValue(entry['key'])) || null}
                        options={(entry.dataset || [])}
                        isOptionEqualToValue={(option, value) => value && option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        onChange={(_,v)=>handleFieldChange(entry['key'], v)}
                        renderInput={(params) => <TextField {...params} label={entry['label']}/>}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'list'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1}/>}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                      disabled={!!entry.disabled}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'file' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <FileUploader
                        error={checkFieldError(entry['key'])}
                        accept=".pdf"
                        name={entry['key']}
                        label={entry['label']}
                        icon={<AttachFileIcon />}
                        handleChange={handleUploadFile}
                        disabled={!!entry.disabled}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['divider'] && 
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12} />
            <Grid item xs={12} sm={8}>
              <Button disabled={isSaving} variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Save Order
              </Button>
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to="/orders">
                Back
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
