import React from 'react';
import Grid from '@mui/material/Grid';
import UtcDatePicker from './UtcDatePicker';
import StyledMainGrid from './StyledMainGrid';
import moment from 'moment';
import { toDate } from '../helpers';

export default function StyledSearchDate(props) {
  return (
    <StyledMainGrid item container columnSpacing {...(props.gridProps ? props.gridProps : {})}>
      <Grid item xs="12" sm="6">
        <UtcDatePicker
          label="Start Date"
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small'
            },
          }}
          value={props.startDate ? moment(toDate(props.startDate)).unix() : null}
          onChange={(v)=>props.onStartDate(v)}
        />
      </Grid>
      <Grid item xs="12" sm="6">
        <UtcDatePicker
          label="End Date"
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small'
            },
          }}
          value={props.endDate ? moment(toDate(props.endDate)).unix() : null}
          onChange={(v)=>props.onEndDate(v)}
        />
      </Grid>
    </StyledMainGrid>
  );
}
