import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

export default function UtcDatePicker(props) {
  let adjustValue = (props.value ? moment.unix(props.value - (moment().utcOffset() * 60)) : null);
  let handleChange = (mVal) => {
    let adjustUnixTime = mVal.unix();
    if ( !isNaN(adjustUnixTime) ) {
      adjustUnixTime += (moment().utcOffset() * 60);
    }
    else {
      adjustUnixTime = null;
    }
    if ( props.onChange ) {
      props.onChange(adjustUnixTime);
    }
  };
  return (
    <DatePicker
      {...props}
      value={adjustValue}
      onChange={handleChange}
    />
  );
}