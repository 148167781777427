import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: '5px'
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '5px'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd) td': {
    backgroundColor: '#f0f0f0'
  },
  '&:nth-of-type(even) td': {
    backgroundColor: '#ffffff'
  },
  '&:nth-of-type(even) th': {
    backgroundColor: '#ffffff'
  }
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns, stickyColumn, border, fontSize } = props;

  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
          },
          "& th:last-child": {
            borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
          },
          "& th:first-child": {
            borderRight: border && stickyColumn ? '2px solid rgb(224, 224, 224)' : undefined
          }
        }}
      >
        {columns.map((column, idx) => (
          column.sortable
            ? <StyledTableCell
                key={column.id}
                align={column.align}
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === column.id ? order : false}
                style={{
                  minWidth: column.minWidth,
                  borderBottom: '2px solid rgb(224, 224, 224)',
                  fontSize: fontSize ? fontSize : undefined,
                  cursor: 'pointer',
                  ...(idx === 0 && stickyColumn ? { position: 'sticky', left: 0, zIndex: 10 } : {})
                }}
                sx={column.sx}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            : <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  borderBottom: '2px solid rgb(224, 224, 224)',
                  fontSize: fontSize ? fontSize : undefined,
                  ...(idx === 0 && stickyColumn ? { position: 'sticky', left: 0, zIndex: 10 } : {})
                }}
                sx={column.sx}
              >
                {column.label}
              </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



export default function EnhancedTable(props) {
  const { columns, items, onRowClick, noPagination, fullHeight, stickyColumn, border, fontSize, rowProps, onTableParams } = props;
  const hasSummary = columns.filter(column => column.summary).length > 0;

  const [page, setPage] = useState(props.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 50);
  const [order, setOrder] = useState(props.order || 'asc');
  const [orderBy, setOrderBy] = useState(props.orderBy || 'name');

  const getSummary = useCallback((column) => {
    return items.reduce((acc, item) => acc + (typeof column.summary === 'function' ? column.summary(item, column) : (item[column.id] || 0)), 0)
  }, [items]);

  const comparator = useCallback((order, orderBy) => (a, b) => {
    let result = 0;
    if (b[orderBy] < a[orderBy]) {
      result = -1;
    }
    if (b[orderBy] > a[orderBy]) {
      result = 1;
    }
    return order === 'desc' ? result : -result;
  }, [])

  const rowsPerPageOptions = useMemo(() => {
    return props.rowsPerPageOptions ? props.rowsPerPageOptions : [25, 50, 100];
  }, [props.rowsPerPageOptions]);

  const tableParams = useMemo(() => {
    let params = {
      pageItems: items.sort(comparator(order, orderBy)),
      pageIndex: page,
      rowsPerPage: rowsPerPage,
      count: items.length,
      order: order,
      orderBy: orderBy
    };
    if ( !noPagination ) {
      let startIdx = params.pageIndex * params.rowsPerPage;
      if ( startIdx >= params.count ) {
        params.pageIndex = Math.floor(params.count / params.rowsPerPage);
        startIdx = params.pageIndex * params.rowsPerPage;
      }
      params.pageItems = params.pageItems.slice(startIdx, startIdx + params.rowsPerPage);
    }
    return params;
  }, [noPagination, items, comparator, order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    setPage(tableParams.pageIndex);
    if ( onTableParams ) {
      onTableParams(tableParams);
    }
  }, [tableParams, onTableParams]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: fullHeight ? '100%' : undefined,
          minHeight: fullHeight ? '150px' : undefined,
          border: (border ? '1px solid rgb(224, 224, 224)' : undefined)
        }}
      >
        <Table stickyHeader size="small" aria-label="a dense table">
          <EnhancedTableHead fontSize={fontSize} border={border} stickyColumn={stickyColumn} columns={columns} order={order} orderBy={orderBy} onRequestSort={handleRequestSort}/>
          <TableBody>
            {
              tableParams.pageItems.map((row, idx) => {
                const {sx, ...restRowProps} = rowProps ? rowProps(row) : {};
                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={row.id || idx}
                    {...restRowProps}
                    onClick={onRowClick ? () => onRowClick(row.id) : undefined}
                    hover={!!onRowClick}
                    sx={{
                      cursor: onRowClick ? 'pointer' : undefined,
                      "& td": {
                        borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                      },
                      "& td:last-child": {
                        borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                      },
                      "& td:first-child": {
                        borderRight: border && stickyColumn ? '2px solid rgb(224, 224, 224)' : undefined
                      },
                      ...sx
                    }}
                  >
                    {
                      columns.map((column, idx) => {
                        const value = row[column.id];
                        return (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: fontSize ? fontSize : undefined }}
                            sx={
                              idx === 0 && stickyColumn ?
                              { position: 'sticky', left: 0, zIndex: 1 }
                              :
                              {}
                            }
                          >
                            <div
                              style={{
                                maxWidth: '100%',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'pre-line',
                                overflow: 'hidden'
                              }}
                              title={column.format ? undefined : value}
                            >
                              {column.format ? column.format(value, row) : value}
                            </div>
                          </StyledTableCell>
                        );
                      })
                    }
                  </StyledTableRow>
                );
              })
            }
            {
              hasSummary &&
              <StyledTableRow
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 1,
                  "& td": {
                    borderTop: '2px solid rgb(224, 224, 224)',
                    borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                  },
                  "& td:last-child": {
                    borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                  }
                }}
              >
                {columns.map((column, idx) =>
                  typeof column.summary === 'string' ?
                  <StyledTableCell
                    sx={{
                      fontWeight:"bold",
                      fontSize: fontSize ? fontSize : undefined,
                      ...(idx === 0 && stickyColumn ? { position: 'sticky', left: 0, zIndex: 10 } : {})
                    }}
                  >
                    {column.summary}
                  </StyledTableCell>
                  :
                  !!column.summary ?
                  <StyledTableCell
                    sx={{
                      fontWeight:"bold",
                      fontSize: fontSize ? fontSize : undefined,
                      ...(idx === 0 && stickyColumn ? { position: 'sticky', left: 0, zIndex: 10 } : {})
                    }}
                  >
                    {column.format ? column.format(getSummary(column)): getSummary(column)}
                  </StyledTableCell>
                  :
                  <StyledTableCell
                    sx={{
                      ...(idx === 0 && stickyColumn ? { position: 'sticky', left: 0, zIndex: 10 } : {})
                    }}
                  />
                )}
              </StyledTableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        !noPagination &&
        <TablePagination
          component="div"
          count={tableParams.count}
          rowsPerPage={tableParams.rowsPerPage}
          page={tableParams.pageIndex}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </React.Fragment>
  );
}
