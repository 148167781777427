import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

export default function ConfirmationButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleButton = (value) => {
    if (typeof props.validate === 'function' && !props.validate()) {
      return false;
    }
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const dialogButtonStyle = {
    padding: '4px 30px'
  };

  let buttonElement = { type: Button };
  if ( props.buttonElement ) {
    buttonElement = { type: props.buttonElement };
  }

  const withTooltip = (btn)=> props.tooltip ? <Tooltip title={props.tooltip}>{btn}</Tooltip> : btn;

  return (
    <React.Fragment>
      {withTooltip(
        <buttonElement.type
          onClick={handleButton}
          startIcon={
            props.loading ?
            <CircularProgress color="info" size={20} thickness={5} sx={{ ml: '-20px' }}/>
            :
            undefined
          }
          disabled={props.loading}
          {...props.buttonProps}
        >
          {props.buttonText}
          {props.buttonProps.children}
        </buttonElement.type>
      )}
      {
        props.additionalButtons &&
        props.additionalButtons.map(btn => {
          let btnElement = { type: Button };
          if ( btn.buttonElement ) {
            btnElement = { type: btn.buttonElement };
          }
          return (
            <btnElement.type onClick={btn.handleButton} disabled={props.loading} {...btn.buttonProps}>
              {btn.buttonText}
              {btn.buttonProps.children}
            </btnElement.type>
          );
        })
      }
      <Dialog
        PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }}
        onClose={handleClose}
        open={open}
      >
        { props.dialogTitle && <DialogTitle>{props.dialogTitle}</DialogTitle> }
        <DialogContent>
          <DialogContentText color="inherit" id="alert-dialog-description">
            {props.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ flex: 'space-around' }}>
          <Button
            style={dialogButtonStyle}
            variant="contained"
            onClick={() => {
              let result = true;
              if (props.id) {
                result = props.handleDialogClickYes(props.id);
              } else {
                result = props.handleDialogClickYes();
              }
              if ( result === undefined || result ) {
                setOpen(false);
              }
            }}
          >
            {props.dialogYesText}
          </Button>
          <Button style={dialogButtonStyle} variant="outlined" onClick={handleClose}>
            {props.dialogNoText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
